import React, { Component } from "react";
import moment from "moment";

const { VERSION } = window["app-config"].APPLICATION_PROPERTIES;
class AppInfo extends Component {
  state = {};
  render() {
    return (
      <>
        <br />
        <br />
        <br />
        <div
          className="container confirm-container"
          style={{
            width: "60%",
            margin: "0 auto",
            padding: "24px",
            height: "auto",
            backgroundColor: "#fff",
          }}
        >
          {" "}
          <center>
            <h2>{`APPLICATION VERSION: ${VERSION}`}</h2>

            <h2>{`DATE: ${moment(new Date()).format(
              "DD-MMMM-YYYY hh:mm A"
            )}`}</h2>
          </center>{" "}
        </div>
      </>
    );
  }
}

export default AppInfo;
