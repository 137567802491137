import React from "react";

const Header = () => {
  let {
    HEADER: { LOGO, BACKGROUND_COLOR, LOGO_WIDTH },
  } = window["theme"].THEME;
  return (
    <nav
      className="navbar navbar-custom navbar-fixed-top"
      role="navigation"
      style={{ backgroundColor: BACKGROUND_COLOR }}
    >
      <div className="container-fluid">
        <div className="navbar-header">
          <center>
            <img
              src={process.env.PUBLIC_URL + `/webticketing/images/${LOGO}`}
              alt="logo"
              style={{ width: LOGO_WIDTH }}
            />
          </center>
        </div>
      </div>
    </nav>
  );
};

export default Header;
