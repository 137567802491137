import React from "react";

const PrimaryButton = ({
  bgColor,
  fontColor,
  onClick,
  btnText,
  disabled = false,
  showLoader = false,
}) => {
  return (
    <>
      <button
        className="btn btn-default btn-font primary-button"
        style={{
          height: "50px",
          width: "275px",
          backgroundColor: bgColor,
          fontSize: "15px",
          outline: "none",
          border: "none",
          color: fontColor,
          borderRadius: "10px",
        }}
        onClick={onClick}
        disabled={disabled}
      >
        {showLoader ? <i className="fa fa-spinner fa-spin"></i> : null} &nbsp;
        {btnText}
      </button>
    </>
  );
};

export default PrimaryButton;
