import React from "react";

const SecondaryButton = ({ color, bgColor, onClick, btnText }) => {
  return (
    <button
      className="btn btn-default btn-clear-form btn-font"
      style={{
        color: color,
        backgroundColor: bgColor,
        borderRadius: "20px",
        border: "1px solid",
        outline: "none",
      }}
      onClick={onClick}
    >
      {btnText}
    </button>
  );
};

export default SecondaryButton;
