import React from "react";

const { FONT_COLOR, INFO_HEADING_COLOR } =
  window["theme"].THEME.USER_DETAILS_SCREEN;

const Info = ({
  slot = "",
  branch = "",
  category = "",
  getTranslations = {},
}) => {
  return (
    <div>
      <div className="info-text-strong" style={{ color: INFO_HEADING_COLOR }}>
        {getTranslations.YouChosen}
      </div>
      <div
        className="info-text"
        style={{ color: FONT_COLOR, fontWeight: "bold" }}
      >
        {category}
      </div>
      <div
        className="info-text"
        style={{ color: FONT_COLOR, fontWeight: "bold" }}
      >
        {branch.BranchTitle}
      </div>
      <div className="info-text" style={{ color: FONT_COLOR }}>
        {branch.Address}
      </div>
      <div
        className="info-text"
        style={{ marginBottom: "10px", color: FONT_COLOR }}
      >
        {slot}
      </div>
    </div>
  );
};

export default Info;
