import React, { Component } from "react";

import Loader from "../components/Loader";
import { HttpService } from "./../httpServices/httpService";
import { Scrollbars } from "react-custom-scrollbars";
import ScrollToTop from "react-scroll-to-top";

import { CustomRadio } from "./../components/CustomRadio/CustomRadio";
const { MODE } = window["app-config"].APPLICATION_PROPERTIES;
const {
  CATEGORY_SCREEN: { FONT_COLOR, BORDER_COLOR },
} = window["theme"].THEME;

var httpService = new HttpService();

class Category extends Component {
  state = {
    categories: [],
    dataLoaded: false,
    selectedCategory: null,
  };

  componentDidMount() {
    this.getCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      MODE === "bs" &&
      prevProps.branch.BranchID !== this.props?.branch?.BranchID
    ) {
      this.getCategories();
    }
  }

  getCategories = async () => {
    var { branch } = this.props;
    try {
      let response;
      if (MODE === "sb") {
        response = await httpService.getAllCategories();
        if (response.data.error !== undefined && response.data.error === true) {
          this.setState({ categories: undefined, dataLoaded: true });
        } else if (response.data.Category === null) {
          this.setState({ categories: [], dataLoaded: true });
        } else {
          this.setState({
            categories: response.data.Category,
            dataLoaded: true,
          });
        }
      } else if (MODE === "bs") {
        response = await httpService.getCategoriesByBranchId(branch.BranchID);
        if (response.data.error !== undefined && response.data.error === true) {
          this.setState({ categories: undefined, dataLoaded: true });
        } else if (response.data.categoryList === null) {
          this.setState({ categories: [], dataLoaded: true });
        } else {
          this.setState({
            categories: response.data.categoryList,
            dataLoaded: true,
          });
        }
      }
    } catch (error) {
      if (error.message.includes("timeout")) {
        this.setState({
          categories: undefined,
          dataLoaded: true,
        });
      } else {
        this.setState({
          categories: [],
          dataLoaded: true,
        });
      }
    }
  };

  onCategorySelect = async (category) => {
    var response = await httpService.getDisabledAppointmentDates(
      this.props.branch.BranchID,
      category.CategoryID,
      this.props.branch.FutureDaysForAppointment
    );

    localStorage.setItem("offDatesDisabledDate", JSON.stringify(response.data.offDates))
    this.setState({
      selectedCategory: category,
      minDate: response.data.MinDate,
      disableDates: response.data.offDates,
      maxDate: response.data.MaxDate,
    });
    this.props.setCategoryForParent(
      category,
      response.data.MinDate,
      response.data.offDates,
      response.data.MaxDate
    );

    console.log(this.props);
  };

  render() {
    var { getTranslations } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <p className="step-info-text" style={{ color: FONT_COLOR }}>
              {getTranslations.CategoryInfoText}
            </p>
          </div>
        </div>

        {!this.state.dataLoaded && (
          <div className="row">
            <div className="col-md-12">
              <Loader />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            {this.state.categories !== null &&
            this.state.categories.length > 0 ? (
              <div
                className="category-container"
                style={{
                  marginTop: "15px",
                  height: "250px",
                  borderRadius: "10px",
                  border: `1px solid ${BORDER_COLOR}`,
                  overflow: "scroll",
                }}
              >
                <br />
                {this.state.categories.map((data, i) => (
                  <React.Fragment key={i}>
                    <div
                      className="row"
                      style={{ padding: "10px 40px" }}
                      key={i}
                    >
                      <CustomRadio
                        value={data}
                        selected={this.state.selectedCategory}
                        label={data.CategoryTitle}
                        onChange={() => this.onCategorySelect(data)}
                        fontColor={FONT_COLOR}
                      />
                    </div>
                  </React.Fragment>
                ))}
              </div>
            ) : null}
          </div>
        </div>
        {/* </Scrollbars> */}
      </>
    );
  }
}

export default Category;
