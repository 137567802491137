/*
 * @Author: Mufaddal Jamali
 * @Date: 2020-07-16 9:00:00
 * @Last Modified by: Mufaddal Jamali
 * @Last Modified time: 2020-09-01 17:45:05
 */

import React, { Component } from "react";
import Phase from "./Phase";

let {
  TICKET_JOURNEY: { DOT_COLOR, COUNTER_COLOR },
} = window["theme"].THEME;
class Journey extends Component {
  state = {
    phase: [
      { id: 4, value: false },
      { id: 3, value: false },
      { id: 2, value: false },
      { id: 1, value: false },
      { id: 0, value: false },
    ],
    greaterPhase: {
      id: 5,
      value: true,
    },
  };

  async componentDidMount() {
    this.phaseChange();
  }

  componentDidUpdate(prevProps) {
    if (parseInt(prevProps.position) !== parseInt(this.props.position)) {
      this.phaseChange();
    }
  }

  phaseChange = () => {
    if (parseInt(this.props.position) > 4) {
      this.setState({
        phase: [
          { id: 4, value: false },
          { id: 3, value: false },
          { id: 2, value: false },
          { id: 1, value: false },
          { id: 0, value: false },
        ],
        phases: [
          { id: 4, value: false },
          { id: 3, value: false },
          { id: 2, value: false },
          { id: 1, value: false },
          { id: 0, value: false },
        ],
        greaterPhase: {
          id: 5,
          value: true,
        },
      });
    } else {
      if (parseInt(this.props.position) !== -1) {
        let phaseReplica = this.state.phase;
        let newPhase = phaseReplica.find(
          (x) => x.id === parseInt(this.props.position)
        );

        newPhase.value = true;

        phaseReplica.forEach((x) => x.id === newPhase.id || x);
        phaseReplica.forEach((x) => {
          if (x.id !== newPhase.id) {
            x.value = false;
          }
          return x.value;
        });

        this.setState({
          phase: phaseReplica,
          greaterPhase: {
            id: 5,
            value: false,
          },
        });
      } else {
        this.setState({
          phase: [
            { id: 4, value: false },
            { id: 3, value: false },
            { id: 2, value: false },
            { id: 1, value: false },
            { id: 0, value: false },
          ],
          phases: [
            { id: 4, value: false },
            { id: 3, value: false },
            { id: 2, value: false },
            { id: 1, value: false },
            { id: 0, value: false },
          ],
          greaterPhase: {
            id: 5,
            value: false,
          },
        });
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <Phase status={this.state.greaterPhase.value} />
        <svg
          width="2em"
          height="2em"
          viewBox="0 0 16 16"
          className="bi bi-three-dots"
          fill={DOT_COLOR}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
          />
        </svg>

        {this.state.phase.map((phase) => (
          <Phase key={phase.id} status={phase.value}></Phase>
        ))}

        <svg
          width="2em"
          height="2em"
          viewBox="0 0 16 16"
          className="bi bi-person-square"
          fill={COUNTER_COLOR}
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginLeft: "30px" }}
        >
          <path
            fillRule="evenodd"
            d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
          />
          <path
            fillRule="evenodd"
            d="M2 15v-1c0-1 1-4 6-4s6 3 6 4v1H2zm6-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
          />
        </svg>
      </React.Fragment>
    );
  }
}

export default Journey;
