import React, { Component } from "react";
import RowFlexContainer from "./../components/RowFlexContainer";
import * as utils from "../utills/utils";
import $ from "jquery";
import PrimaryButton from "./../components/PrimaryButton";
import { HttpService } from "./../httpServices/httpService";
import swal from "sweetalert";
import moment from "moment";
import Journey from "./../components/Journey/Journey";

const queryString = require("query-string");

let appointmentNumber = queryString.parse(
  window.location.search
).appointmentnumber;

let {
  REDIRECT_TO_CURRENT_PAGE,
  APP_ROUTE,
  ENABLE_CHECKIN,
  REFRESH_INTERVAL,
  DEFAULT_PHONE_PREFIX,
  URLs: { WIZARD_URL },
} = window["app-config"].APPLICATION_PROPERTIES;

let {
  CHECKIN_SCREEN: {
    FONT_COLOR,
    CHECKIN_BTN_COLOR,
    CANCEL_TICKET_BTN_COLOR,
    BTN_FONT_COLOR,
  },
  ICONS: { REFRESH_ICON },
} = window["theme"].THEME;

let httpService = new HttpService();

class Checkin extends Component {
  state = {
    isSuccessfullyCheckedIn: false,
    translations: {},
    appointmentNumber: null,
    phoneNumber: null,
    emailAddress: null,
    dataLoaded: true,
    verifyAppointmentResponse: "",
    issueTicketResponse: null,
    enableBtn: false,
    redirectUrl: "",
    isTicketCalled: false,
  };

  componentDidMount() {
    this.setState({
      translations: utils.setTranslations(),
      redirectUrl: utils.removeKeyFromUrl(
        [REDIRECT_TO_CURRENT_PAGE ? "checkin" : APP_ROUTE],
        "appointmentnumber"
      ),
    });

    if (
      localStorage.getItem("checkedIn") === "true" &&
      localStorage.getItem("checkedInDate") == moment().toDate().toDateString()
    ) {
      this.setState(JSON.parse(localStorage.getItem("prevCheckedInData")));
      setInterval(() => {
        this.getTicketStatus();
      }, REFRESH_INTERVAL);

      utils.onBrowserReload(this.getTicketStatus);
    } else {
      if (appointmentNumber !== undefined && appointmentNumber !== "") {
        this.verifyAppointmentInfo(
          this.state.emailAddress,
          this.state.phoneNumber,
          appointmentNumber,
          ""
        );
      }
    }
  }

  onChange = (e) => {
    if (e.target.id === "appointmentNumber") {
      if (
        e.target.value !== "" &&
        e.target.value.length > 0 &&
        utils.validation.isNumber(e.target.value)
      ) {
        this.setState({
          enableBtn: true,
          appointmentNumber: e.target.value,
        });
      } else {
        this.setState({ enableBtn: false });
      }
    } else if (e.target.id === "phoneNumber") {
      if (
        (e.target.value.length === 0 && e.target.value === "") ||
        /\s/.test(e.target.value) ||
        !/^[0-9+]+$/.test(e.target.value)
      ) {
        $("#phoneNumber").css("border-color", "red");
        this.setState({ enableBtn: false });
      } else {
        $("#phoneNumber").css("border-color", "#dadada");
        this.setState({ enableBtn: true, phoneNumber: e.target.value });
      }
    } else if (e.target.id === "emailAddress") {
      if (
        e.target.value !== "" &&
        e.target.value.length > 0 &&
        utils.validateEmail(e.target.value)
      ) {
        this.setState({ enableBtn: true, emailAddress: e.target.value });
      } else {
        $("#emailAddress").css("border-color", "#dadada");
        this.setState({ enableBtn: false });
      }
    }
  };

  // API CALLS

  verifyAppointmentInfo = async (
    email,
    phoneNumber,
    appointmentNumber,
    branchIp
  ) => {
    this.setState({ dataLoaded: false });
    try {
      let verifyAppointmentResponse = await httpService.verifyAppointmentInfo(
        email,
        phoneNumber,
        appointmentNumber,
        branchIp
      );
      console.log("VERIFY APPOINTMENT RESPONSE: ", verifyAppointmentResponse);
      if (
        verifyAppointmentResponse.data.error !== undefined &&
        verifyAppointmentResponse.data.error === true
      ) {
        utils.showErrMsg(
          this.state.translations.Error,
          this.state.translations.ServerNotResponding,
          this.state.translations.Close,
          this.state.redirectUrl
        );
      } else if (
        verifyAppointmentResponse.data.response_code === 100 &&
        ENABLE_CHECKIN === true &&
        verifyAppointmentResponse.data.appointment_info.state === "ON_TIME"
      ) {
        this.setState({
          verifyAppointmentResponse,
          dataLoaded: true,
        });

        this.issueTicket();
      } else if (
        (verifyAppointmentResponse.data.response_code === 100 ||
          verifyAppointmentResponse.data.response_code === 106) &&
        ENABLE_CHECKIN === true &&
        verifyAppointmentResponse.data.appointment_info.state === "EARLY"
      ) {
        this.setState({ dataLoaded: true });

        utils.showErrMsg(
          this.state.translations.Error,
          this.state.translations.EarlyIssueTicket,
          this.state.translations.Close,
          this.state.redirectUrl
        );
      } else if (
        verifyAppointmentResponse.data.response_code === 100 &&
        ENABLE_CHECKIN === true &&
        verifyAppointmentResponse.data.appointment_info.state === "LATE"
      ) {
        this.setState({ dataLoaded: true });

        utils.showErrMsg(
          this.state.translations.Error,
          this.state.translations.LateIssueTicket,
          this.state.translations.Close,
          this.state.redirectUrl
        );
      } else if (
        verifyAppointmentResponse.data.response_code === 100 &&
        ENABLE_CHECKIN === false
      ) {
        this.setState({ dataLoaded: true });
        utils.showSuccessMessage(
          this.state.translations.AppointmentIsValid,
          this.state.translations.Close
        );
      } else if (
        verifyAppointmentResponse.data.response_code === 103 &&
        verifyAppointmentResponse.data.appointment_info.appt_state ===
          "TICKET_ISSUED" &&
        ENABLE_CHECKIN === true
      ) {
        this.setState({ dataLoaded: true });
        console.log("ELSE IF 183");
        utils.showErrMsg(
          this.state.translations.Error,
          this.state.translations.TicketAlreadyIssued,
          this.state.translations.Close,
          this.state.redirectUrl
        );
      } else if (verifyAppointmentResponse.data.response_code === 102) {
        this.setState({ dataLoaded: true });

        utils.showErrMsg(
          this.state.translations.Error,
          this.state.translations.AppointmentNotFound,
          this.state.translations.Close,
          this.state.redirectUrl
        );
      }
    } catch (error) {
      console.log("ERROR IN VERIFY APPOINTMENT", error.message);
      if (error.message.includes("timeout")) {
        utils.showErrMsg(
          this.state.translations.Error,
          this.state.translations.ServerNotResponding,
          this.state.translations.Close,
          this.state.redirectUrl
        );
      } else {
        utils.showErrMsg(
          this.state.translations.Error,
          this.state.translations.SomeThingWentWrong,
          this.state.translations.Close,
          this.state.redirectUrl
        );
      }
      this.setState({ dataLoaded: true });
    }
  };

  issueTicket = async () => {
    this.setState({ dataLoaded: false });
    if (this.state.verifyAppointmentResponse.data.response_code === 100) {
      try {
        var { appointment_info } = this.state.verifyAppointmentResponse.data;
        let branchId = appointment_info.branch_id;
        let branchCode = appointment_info.branch_code;
        let categoryId = appointment_info.category_id;
        let priority = appointment_info.priority;
        let emailAddress = appointment_info.user_id;
        let phoneNumber = appointment_info.payloads[1].fieldValue;
        let escapedPhoneNumber = phoneNumber.replace("+", "%2b");
        let firstName = appointment_info.payloads[7].fieldValue;
        let surName = appointment_info.payloads[8].fieldValue;
        let additionalInformation = appointment_info.payloads[6].fieldValue;

        let issueTicketResponse = await httpService.issueTicket(
          branchId,
          categoryId,
          priority,
          emailAddress,
          3,
          localStorage.getItem("lang") === "es"
            ? "Sp"
            : localStorage.getItem("lang"),
          firstName + "%20" + surName,
          escapedPhoneNumber,
          emailAddress,
          "1",
          additionalInformation
        );
        console.log("RESPONSE OF ISSUE TICKET : ", issueTicketResponse);

        if (
          issueTicketResponse.data.error !== undefined &&
          issueTicketResponse.data.error === true
        ) {
          utils.showErrMsg(
            this.state.translations.Error,
            this.state.translations.ServerNotResponding,
            this.state.translations.Close,
            this.state.redirectUrl
          );
          this.setState({ dataLoaded: true });
        } else if (
          issueTicketResponse.data.ticket_info !== undefined &&
          issueTicketResponse.data.ticket_info !== null
        ) {
          this.setState({
            issueTicketResponse: issueTicketResponse,
            isSuccessfullyCheckedIn: true,
            dataLoaded: true,
          });
          localStorage.setItem("checkedIn", true);
          localStorage.setItem("prevCheckedInData", JSON.stringify(this.state));
          localStorage.setItem(
            "checkedInDate",
            moment().toDate().toDateString()
          );

          this.markAppointmentUsed();
        } else if (issueTicketResponse.data.resp_status === "-1") {
          this.setState({ isSuccessfullyCheckedIn: false, dataLoaded: true });

          utils.showErrMsg(
            this.state.translations.Error,
            this.state.translations.TicketNotIssued,
            this.state.translations.Close,
            this.state.redirectUrl
          );
        } else if (
          issueTicketResponse.data.resp_message ===
          "You already have an issued ticket for the category, in the branch."
        ) {
          this.setState({ isSuccessfullyCheckedIn: false, dataLoaded: true });

          utils.showErrMsg(
            this.state.translations.Error,
            this.state.translations.TicketAlreadyIssued,
            this.state.translations.Close,
            this.state.redirectUrl
          );
        }
      } catch (error) {
        console.log("ERROR IN ISSUE TICKET ", error.message);
        if (error.message.includes("timeout")) {
          utils.showErrMsg(
            this.state.translations.Error,
            this.state.translations.ServerNotResponding,
            this.state.translations.Close,
            this.state.redirectUrl
          );
          this.setState({ dataLoaded: true });
        } else if (error.message.includes("404")) {
          utils.showErrMsg(
            this.state.translations.Error,
            this.state.translations.UrlNotFound,
            this.state.translations.Close,
            this.state.redirectUrl
          );
          this.setState({ dataLoaded: true });
        } else if (error.message.includes("Network Error")) {
          utils.showErrMsg(
            this.state.translations.Error,
            this.state.translations.InternetConnectionError,
            this.state.translations.Close,
            this.state.redirectUrl
          );
          this.setState({ dataLoaded: true });
        } else {
          utils.showErrMsg(
            this.state.translations.Error,
            this.state.translations.SomeThingWentWrong,
            this.state.translations.Close,
            this.state.redirectUrl
          );
          this.setState({ dataLoaded: true });
        }
      }
    }
  };

  cancelTicket = async () => {
    var { appointment_info } = this.state.verifyAppointmentResponse.data;
    swal(
      this.state.translations.CancelTicketConfirmation,

      {
        icon: "warning",
        buttons: {
          no: {
            text:
              this.state.translations.No === undefined
                ? "No"
                : this.state.translations.No,
            value: "no",
          },
          yes: {
            text:
              this.state.translations.Yes === undefined
                ? "Yes"
                : this.state.translations.Yes,
            value: "yes",
            closeModal: false,
          },
        },
      }
    ).then(async (value) => {
      switch (value) {
        case "yes":
          let branchId = appointment_info.branch_id;
          let ticketId =
            this.state.issueTicketResponse.data.ticket_info.ticket_id;
          let ticketNumber =
            this.state.issueTicketResponse.data.ticket_info.ticket_no;
          let sqn = this.state.issueTicketResponse.data.ticket_info.categoryid;
          let emailAddress = appointment_info.user_id;
          let phoneNumber = appointment_info.payloads[1].fieldValue;
          let language =
            localStorage.getItem("lang") === "es"
              ? "Sp"
              : localStorage.getItem("lang");
          try {
            let cancelTicketResponse = await httpService.cancelTicket(
              emailAddress,
              ticketId,
              branchId,
              language,
              ticketNumber,
              sqn,
              phoneNumber
            );
            console.log("CANCEL TICKET RESPONSE: ", cancelTicketResponse);
            if (cancelTicketResponse.data.resp_message === "Ticket cancelled") {
              swal.close();

              localStorage.removeItem("checkedIn");
              localStorage.removeItem("checkedInDate");
              localStorage.removeItem("prevCheckedInData");

              utils.redirect(this.state.redirectUrl);
            } else if (
              cancelTicketResponse.data.resp_message === "Ticket not found" ||
              cancelTicketResponse.data.resp_message ===
                "Operation not permitted"
            ) {
              utils.showErrMsg(
                this.state.translations.Error,
                this.state.translations.TicketNotFound,
                this.state.translations.Close,
                this.state.redirectUrl
              );
              localStorage.removeItem("checkedIn");
              localStorage.removeItem("checkedInDate");
              localStorage.removeItem("prevCheckedInData");
              utils.redirect(this.state.redirectUrl);
            } else {
              utils.showErrMsg(
                this.state.translations.Error,
                this.state.translations.SomeThingWentWrong,
                this.state.translations.Close,
                this.state.redirectUrl
              );
            }
          } catch (error) {
            if (error.message.includes("404")) {
              utils.showErrMsg(
                this.state.translations.Error,
                this.state.translations.UrlNotFound,
                this.state.translations.Close,
                this.state.redirectUrl
              );
            } else if (error.message.includes("Network Error")) {
              utils.showErrMsg(
                this.state.translations.Error,
                this.state.translations.InternetConnectionError,
                this.state.translations.Close,
                this.state.redirectUrl
              );
            }
          }

          break;
        case "no":
          break;

        default:
      }
    });
  };

  markAppointmentUsed = async () => {
    var { appointment_info } = this.state.verifyAppointmentResponse.data;
    try {
      let appointmentNumber = appointment_info.appointment_number;
      let emailAddress = appointment_info.user_id;
      let ticketNumber =
        this.state.issueTicketResponse.data.ticket_info.ticket_no;

      let response = await httpService.markAppointmentUsedAfterTicketIssue(
        appointmentNumber,
        emailAddress,
        ticketNumber
      );
      if (response.data.error !== undefined && response.data.error === true) {
        utils.showErrMsg(
          this.state.translations.Error,
          this.state.translations.ServerNotResponding,
          this.state.translations.Close,
          this.state.redirectUrl
        );
      }
    } catch (error) {
      console.log("ERROR IN MARKING APPOINTMENT USED: ", error.message);
      if (error.message.includes("timeout")) {
        utils.showErrMsg(
          this.state.translations.Error,
          this.state.translations.ServerNotResponding,
          this.state.translations.Close,
          this.state.redirectUrl
        );
      } else if (error.message.includes("404")) {
        utils.showErrMsg(
          this.state.translations.Error,
          this.state.translations.UrlNotFound,
          this.state.translations.Close,
          this.state.redirectUrl
        );
      } else if (error.message.includes("Network Error")) {
        utils.showErrMsg(
          this.state.translations.Error,
          this.state.translations.InternetConnectionError,
          this.state.translations.Close,
          this.state.redirectUrl
        );
      } else {
        utils.showErrMsg(
          this.state.translations.Error,
          this.state.translations.SomeThingWentWrong,
          this.state.translations.Close,
          this.state.redirectUrl
        );
      }
    }
  };

  getTicketStatus = async () => {
    let prevState = JSON.parse(localStorage.getItem("prevCheckedInData"));
    let branchId =
      prevState.verifyAppointmentResponse.data.appointment_info.branch_id;
    let categoryId =
      prevState.verifyAppointmentResponse.data.appointment_info.category_id;
    let ticketId = prevState.issueTicketResponse.data.ticket_info.ticket_id;
    let ticketNumber = prevState.issueTicketResponse.data.ticket_info.ticket_no;
    try {
      let response = await httpService.getTicketStatus(
        branchId,
        ticketId,
        ticketNumber,
        categoryId
      );
      console.log("TICKET STATUS RESPONSE: ", response);
      if (response.data.error !== undefined && response.data.error) {
        utils.showErrMsg(
          prevState.translations.Error,
          prevState.translations.ServerNotResponding,
          prevState.translations.Ok,
          prevState.redirectUrl
        );
      } else {
        if (response.data.resp_status === 1) {
          let issueTicketResponse = {
            ...prevState.issueTicketResponse,
          };
          issueTicketResponse["data"]["ticket_info"][
            "category_approx_waittime"
          ] = response.data.est_wait_time;
          issueTicketResponse["data"]["ticket_info"]["ticket_in_wait"] =
            response.data.position_in_queue <= 0
              ? 0
              : parseInt(response.data.position_in_queue);
          this.setState({ issueTicketResponse: issueTicketResponse });
          localStorage.setItem("prevCheckedInData", JSON.stringify(prevState));
        } else if (response.data.resp_status === 2) {
          utils.notify();
          this.setState({ isTicketCalled: true });
        } else if (response.data.resp_status === 3) {
          localStorage.clear();
          utils.showErrMsg(
            prevState.translations.Error,
            prevState.translations.TicketNotFound,
            prevState.translations.Ok,
            prevState.redirectUrl
          );
          setTimeout(() => {
            localStorage.clear();
            utils.redirect(prevState.redirectUrl);
          }, this.popupExpiry);
        }
      }
    } catch (error) {
      console.log("ERR IN GET TICKET STATUS", error.message);
      if (error.message.includes("timeout")) {
        utils.showErrMsg(
          prevState.translations.Error,
          prevState.translations.ServerNotResponding,
          prevState.translations.Ok,
          prevState.redirectUrl
        );
      } else {
        utils.showErrMsg(
          prevState.translations.Error,
          prevState.translations.SomeThingWentWrong,
          prevState.translations.Ok,
          prevState.redirectUrl
        );
      }
    }
  };

  // END OF API CALLS

  renderTicketInfo = () => {
    var { ticket_info } = this.state.issueTicketResponse.data;
    return (
      <>
        <div
          className="container confirm-container"
          style={{
            width: "80%",
            margin: "0 auto",
            padding: "24px",
            height: "auto",
            backgroundColor: "#fff",
          }}
        >
          <center>
            <div className="row">
              <div className="col-md-12">
                <span onClick={this.getTicketStatus}>
                  <img
                    className="refresh-icon"
                    src={
                      process.env.PUBLIC_URL +
                      `/webticketing/images/${REFRESH_ICON}`
                    }
                    alt="refresh-icon"
                  />
                </span>
              </div>
            </div>
            <h1 className="font-ghotam" style={{ color: FONT_COLOR }}>
              {this.state.translations.IssueTicketConfirmedMessage}
            </h1>
            <br />
            {/* <p className="font-ghotam">
              {`${
                this.state.translations.EstimatedWaitTimeMessage
              } ${Math.floor(ticket_info.category_approx_waittime / 60)} ${
                this.state.translations.Minutes
              }`}
            </p> */}

            <p
              className="font-ghotam"
              style={{ color: FONT_COLOR }}
            >{`${this.state.translations.ThereArePeople} ${ticket_info.ticket_in_wait} ${this.state.translations.TicketInWait}`}</p>
            <br />
            <Journey position={Number(ticket_info.ticket_in_wait)} />

            <h1>{this.state.translations.TicketNumber}</h1>

            <p
              style={{
                fontSize: "56px",
                fontWeight: "bold",
                color: FONT_COLOR,
              }}
              className="font-ghotam"
            >
              <strong>{ticket_info.ticket_no}</strong>
            </p>

            <h2 className="font-ghotam" style={{ color: FONT_COLOR }}>
              {this.state.translations.TicketIssuedTime}
            </h2>
            <p className="font-ghotam" style={{ color: FONT_COLOR }}>
              {ticket_info.issued_on}
            </p>
            <br />
            <PrimaryButton
              btnText={this.state.translations.ModifyOrCancelTicket}
              bgColor={CANCEL_TICKET_BTN_COLOR}
              fontColor={BTN_FONT_COLOR}
              onClick={this.cancelTicket}
            />
          </center>
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <div
          style={{
            marginBottom: this.state.isSuccessfullyCheckedIn ? "10px" : "80px",
          }}
        ></div>
        {this.state.isSuccessfullyCheckedIn ? (
          this.renderTicketInfo()
        ) : (
          <div
            className="container confirm-container"
            style={{
              width: "60%",
              margin: "0 auto",
              padding: "24px",
              height: "auto",
              backgroundColor: "#fff",
            }}
          >
            <div className="modify-appt-input-container">
              <div className="form-group">
                <center>
                  <label
                    className="modify-input-labels"
                    style={{ color: FONT_COLOR }}
                  >
                    {this.state.translations.AppointmentNumber}
                  </label>
                </center>
                <input
                  type="text"
                  className="form-control modify-inputs"
                  placeholder={
                    this.state.translations.AppointmentNumberPlaceholder
                  }
                  id="appointmentNumber"
                  maxLength={10}
                  style={{ border: "1px solid #d8d8d8" }}
                  onChange={this.onChange}
                />
              </div>

              <div className="form-group">
                <center>
                  <label
                    className="modify-input-labels"
                    style={{ color: FONT_COLOR }}
                  >
                    {this.state.translations.EmailAddress}
                  </label>
                </center>
                <input
                  type="text"
                  className="form-control modify-inputs"
                  id="emailAddress"
                  placeholder={this.state.translations.EmailAddressPlaceHolder}
                  style={{ border: "1px solid #d8d8d8" }}
                  onChange={this.onChange}
                />
              </div>

              <div className="form-group">
                <center>
                  <label
                    className="modify-input-labels"
                    style={{ color: FONT_COLOR }}
                  >
                    {this.state.translations.PhoneNumber}
                  </label>
                </center>
                <input
                  type="text"
                  id="phoneNumber"
                  maxLength={14}
                  placeholder={this.state.translations.PhoneNumberPlaceholder}
                  className="form-control modify-inputs"
                  style={{ border: "1px solid #d8d8d8" }}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <RowFlexContainer>
              {this.state.enableBtn && (
                <PrimaryButton
                  bgColor={CHECKIN_BTN_COLOR}
                  fontColor={BTN_FONT_COLOR}
                  btnText={this.state.translations.CheckIn}
                  onClick={
                    !this.state.dataLoaded
                      ? null
                      : () =>
                          this.verifyAppointmentInfo(
                            this.state.emailAddress,
                            `${
                              this.state.phoneNumber !== null
                                ? DEFAULT_PHONE_PREFIX + this.state.phoneNumber
                                : this.state.phoneNumber
                            }`,
                            this.state.appointmentNumber,
                            ""
                          )
                  }
                  showLoader={!this.state.dataLoaded}
                />
              )}
            </RowFlexContainer>
          </div>
        )}
      </>
    );
  }
}

export default Checkin;
