import React, { Component } from "react";
import { HttpService } from "./../httpServices/httpService";
import Info from "./../components/Info";
import RowFlexContainer from "./../components/RowFlexContainer";
import * as utils from "../utills/utils";
import $ from "jquery";
import moment from "moment";
import swal from "sweetalert";
import PrimaryButton from "../components/PrimaryButton";
import SecondaryButton from "./../components/SecondaryButton";

const {
  FONT_COLOR,
  LABEL_COLOR,
  BTN_COLOR,
  BTN_TEXT_COLOR,
  RETURN_BTN_COLOR,
  BTN_DISABLED_COLOR,
  BTN_DISABLED_TEXT_COLOR,
  CLEAR_BTN_COLOR,
  CLEAR_BTN_TEXT_COLOR,
  FIELDS_BORDER_COLOR,
  CHECKBOX_LABEL_COLOR,
} = window["theme"].THEME.USER_DETAILS_SCREEN;
const queryString = require("query-string");

let httpService = new HttpService();
let qsLanguage = queryString.parse(window.location.search).lang;

let {
  DEFAULT_LANGUAGE,
  URLs: { WIZARD_URL },
  DEFAULT_PHONE_PREFIX,
} = window["app-config"].APPLICATION_PROPERTIES;
let language =
  window["localization"][qsLanguage] !== undefined
    ? window["localization"][qsLanguage]
    : window["localization"][DEFAULT_LANGUAGE];

let { USER_DETAILS_SCREEN } = window["theme"].THEME;

let controls = USER_DETAILS_SCREEN.CONTROLS.primaryControls(language);
let secondaryControls =
  USER_DETAILS_SCREEN.CONTROLS.secondaryControls(language);

controls = controls.filter((c) => {
  return c.isEnabled === true;
});

secondaryControls = secondaryControls.filter((sc) => {
  return sc.isEnabled === true;
});

class UserDetails extends Component {
  state = {
    isValidFields: false,
    isValidSecondaryControls: undefined,
    controls: controls,
    secondaryControls: secondaryControls,
    response: null,
    dataLoaded: true,
    payloads: {},
  };

  componentDidMount() {
    localStorage.removeItem("FullName");
    let controls = this.state.controls;
    let isAllValidValues = true;
    let isAllValidSecondaryControls = true;
    for (let i = 0; i < controls.length; i++) {
      if (controls[i].validate === true && controls[i].isValid === false) {
        isAllValidValues = false;
        break;
      } else if (
        controls[i].value === null &&
        controls[i].isMandatory === true
      ) {
        isAllValidValues = false;
        break;
      }
    }
    for (let i = 0; i < secondaryControls.length; i++) {
      if (
        secondaryControls[i].isMandatory === true &&
        secondaryControls[i].isChecked === false
      ) {
        isAllValidSecondaryControls = false;
        break;
      }
    }

    this.setState({
      isValidFields: isAllValidValues,
      isValidSecondaryControls: isAllValidSecondaryControls,
    });
  }

  issueAppointment = async () => {

    const dateOf = this.getFieldValue("dateofBirth").split(',');

    this.setState({ dataLoaded: false });
    var {translations} = this.props;
    if(dateOf[0]<=utils.formatDate()){
      try {
        var { category, branch, timeSlot } = this.props;
  
        let { data } = await httpService.issueAppointment(
          category.CategoryID,
          branch.BranchID,
          branch.BranchTitle,
          branch.Address,
          branch.ContactNo,
          timeSlot.split("|")[2],
          2,
          moment(timeSlot.split("|")[0], "DD-MM-YYYY").format("YYYY-MM-DD"),
  
          this.getFieldValue("EmailId"),
          1,
          0,
          //this.getPayloads(),
          this.getAppointmentPayloads(),
          localStorage.getItem("lang") === "es"
            ? "Sp"
            : localStorage.getItem("lang"),
          timeSlot.split("|")[1]
        );
  
        if (data.error !== undefined && data.error === true) {
          utils.showErrorMessage(
            translations.Error,
            translations.ServerNotResponding,
            translations.Ok
          );
        } else {
          this.setState({ response: data, dataLoaded: true });
          this.props.setAPIResponseForParent({
            response: data,
            userDetails: this.state.controls,
          });
          if (data.issueAppointment.resp_status === -6) {
            swal(
              translations.AppointmentAlreadyExists,
              translations.YouAlreadyHaveAnAppointment,
  
              {
                icon: "warning",
                buttons: {
                  keepPreviousOne: {
                    text: translations.KeepPrevious,
                    value: "keepPreviousOne",
                  },
                  reschedule: {
                    text: translations.Reschedule,
                    value: "reschedule",
                    closeModal: false,
                  },
                },
              }
            ).then(async (value) => {
              switch (value) {
                case "keepPreviousOne":
                  if (data.previous_appointment_info != null) {
                    this.props.hadleAppointmentIssue();
                  }
                  break;
  
                case "reschedule":
                  this.rescheduleAppointment();
  
                  break;
  
                default:
              }
            });
          } else if (data.issueAppointment.resp_status === 1) {
            this.props.hadleAppointmentIssue();
          } else if (
            data.issueAppointment.resp_message ===
            "Appointment quota for this time slot has reached to its maximum value. Try selecting different time slot."
          ) {
            utils.showErrorMessage(
              translations.Error,
              translations.TimeSlotQuotaExceed,
              translations.Ok
            );
          } else {
            utils.showErrorMessage(
              translations.Error,
              translations.SomeThingWentWrong,
              translations.Ok
            );
          }
        }
      } catch (error) {
        // console.log("ERROR: ", error.message);
        if (error.message.includes("timeout")) {
          utils.showErrorMessage(
            translations.Error,
            translations.ServerNotResponding,
            translations.Ok
          );
        } else {
          utils.showErrorMessage(
            translations.Error,
            translations.SomeThingWentWrong,
            translations.Ok
          );
        }
      }
    }else{
      this.setState({ dataLoaded: true });
      utils.showAlertMeg(
        translations.Error,
        translations.ShowDateOfBirthAlert,
        translations.Ok
      );
    }
  };

  rescheduleAppointment = async () => {

    const dateOf = this.getFieldValue("dateofBirth").split(',');

    this.setState({ dataLoaded: false });
    var {translations} = this.props;
    if(dateOf[0]<=utils.formatDate()){
      try {
        var { category, branch, timeSlot, apiReponse } = this.props;
        let { data } = await httpService.rescheduleAppointment(
          apiReponse.previous_appointment_info === null
            ? apiReponse.issueAppointment.appointment_number
            : apiReponse.previous_appointment_info.appointment_number,
          apiReponse.previous_appointment_info === null
            ? category.CategoryID
            : apiReponse.previous_appointment_info.category_id,
          category.CategoryID,
          branch.BranchID,
          branch.BranchTitle,
          branch.Address,
          branch.ContactNo,
          timeSlot.split("|")[2],
          2,
          moment(timeSlot.split("|")[0], "DD-MM-YYYY").format("YYYY-MM-DD"),
  
          this.getFieldValue("EmailId"),
          1,
          0,
          //this.getPayloads(),
          this.getAppointmentPayloads(),
          localStorage.getItem("lang") === "es"
            ? "Sp"
            : localStorage.getItem("lang"),
          timeSlot.split("|")[1]
        );
        if (data.error !== undefined && data.error === true) {
          utils.showErrorMessage(
            translations.Error,
            translations.SomeThingWentWrong,
            translations.Ok
          );
        } else {
          if (data.issueAppointment.resp_message === "success") {
            this.setState({
              response: data,
              dataLoaded: true,
            });
            this.props.setAPIResponseForParent({
              response: data,
              userDetails: this.state.controls,
            });
            swal.close();
            this.props.hadleAppointmentIssue();
          } else if (
            data.issueAppointment.resp_message ===
            "Appointment quota for this time slot has reached to its maximum value. Try selecting different time slot."
          ) {
            utils.showErrorMessage(
              translations.Error,
              translations.TimeSlotQuotaExceed,
              translations.Ok
            );
          } else if (data.issueAppointment.resp_status === 6) {
            utils.showErrorMessage(
              translations.Error,
              translations.AppointmentAlreadyCanceled,
              translations.Ok
            );
          } else if (data.issueAppointment.resp_status === 7) {
            utils.showErrorMessage(
              translations.Error,
              translations.AppointmentExpired,
              translations.Ok
            );
          } else if (data.issueAppointment.resp_status === 3) {
            utils.showErrorMessage(
              translations.Error,
              translations.CantRescheduleApptTcktIssued,
              translations.Ok
            );
          } else if (data.issueAppointment.resp_status === -2) {
            utils.showErrorMessage(
              translations.Error,
              translations.AlreadyServed,
              translations.Ok
            );
          } else if (data.issueAppointment.resp_status === -3) {
            utils.showErrorMessage(
              translations.Error,
              translations.ApptCanceledAutomatically,
              translations.Ok
            );
          } else if (data.issueAppointment.resp_status === -6) {
            utils.showErrorMessage(
              translations.Error,
              translations.YouAlreadyHaveAnAppointment,
              translations.Ok
            );
          } else {
            utils.showErrorMessage(
              translations.Error,
              translations.SomeThingWentWrong,
              translations.Ok
            );
          }
        }
      } catch (error) {
        console.log("IN CATCH OF RA: ", error.message);
        if (error.message.includes("timeout")) {
          utils.showErrorMessage(
            translations.Error,
            translations.ServerNotResponding,
            translations.Ok
          );
        } else {
          utils.showErrorMessage(
            translations.Error,
            translations.SomeThingWentWrong,
            translations.Ok
          );
        }
      }
    }else{
      this.setState({ dataLoaded: true });
      utils.showAlertMeg(
        translations.Error,
        translations.ShowDateOfBirthAlert,
        translations.Ok
      );
    }
  };

  handleControlChange = (e) => {
    let control = controls.find((c) => {
      return c.id === e.target.id;
    });
    let index = controls.indexOf(control);
    control.value = e.target.value;
    controls[index] = control;
    var regex = control.regex;

    if (e.target.value.length > control.maxLength) {
      e.preventDefault();
      return false;
    }

    if (
      control.validate === true &&
      regex !== null &&
      !regex.test(e.target.value)
    ) {
      control.isValid = false;
    } else if (
      control.isMandatory === true &&
      (control.value === null || control.value === "")
    ) {
      control.isValid = false;
    } else {
      control.isValid = true;
    }

    for (let i = 0; i < controls.length; i++) {
      let c = controls[i];
      if (c.isMandatory === true && (c.value === null || c.value === "")) {
        this.setState({ isValidFields: false, controls: controls });
        break;
      } else if (c.validate === true && c.isValid === false) {
        this.setState({ isValidFields: false, controls: controls });
        break;
      } else {
        this.setState({ isValidFields: true, controls: controls });
      }
    }
  };

  handleCheckBoxChange = (e) => {
    let control = secondaryControls.find((sc) => {
      return sc.id === e.target.id;
    });
    if (control.isMandatory === true && e.target.checked === true) {
      this.setState({ isValidSecondaryControls: true });
      // console.log("IN IF TRUE");
    } else if (control.isMandatory === true && e.target.checked === false) {
      this.setState({ isValidSecondaryControls: false });
    }

    let index = secondaryControls.indexOf(control);
    control.isChecked = e.target.checked;
    secondaryControls[index] = control;
    // console.log("PRI: ", this.state.isValidFields);
    // console.log("SEC: ", this.state.isValidSecondaryControls);
  };

  getFieldValue = (fieldName) => {
    let control = this.state.controls.find((c) => c.fieldName === fieldName);
    // console.log("control :",control);
    if (control === undefined || control.value === null || control.value == "") {
      return "";
    } else {
      if((fieldName == "additionalInfo" ||fieldName == "dateofBirth" || fieldName == "PassportNumber") && control.value !== " " ){
        return control.value + ",";
      }
      else{
        return control.value
      }
      
    }
  
    

  
  };
  
  getPayloads = () => {
    var filteredPayloads = [];
    var payloads = [];
    let ctrls = controls;
    for (let i = 0; i < ctrls.length; i++) {
      var object = {};
      object["fieldName"] = ctrls[i].fieldName;
      object["fieldValue"] = ctrls[i].value;

      filteredPayloads.push(object);
    }

    filteredPayloads.forEach((o) => {
      let temp = payloads.find((x) => {
        if (x && x.fieldName === o.fieldName) {
          x.fieldValue += ", " + o.fieldValue;
          return true;
        }
      });
      if (!temp) payloads.push(o);
    });
    let arr = [...payloads];
    arr.push({
      fieldName: "AppUrl",
      fieldValue: `${utils.urls.MODIFY_APPOINTMENT}${localStorage.getItem(
        "lang"
      )}`,
    });
    return arr;
  };

  getAppointmentPayloads = () => {
    let paylaods = [
      {
        fieldName: "EmailId",
        fieldValue:
          this.getFieldValue("EmailId") !== ""
            ? this.getFieldValue("EmailId")
            : this.getFieldValue("MobileNumber") !== ""
            ? this.getFieldValue("MobileNumber")
            : "",
      },
      {
        fieldName: "MobileNumber",
        fieldValue: `${DEFAULT_PHONE_PREFIX}${this.getFieldValue(
          "MobileNumber"
        )}`,
      },
      {
        fieldName: "PhoneModel",
        fieldValue: "",
      },
      {
        fieldName: "PhoneColor",
        fieldValue: "",
      },
      {
        fieldName: "PhoneService",
        fieldValue: "",
      },
      {
        fieldName: "IMEINumber",
        fieldValue: "",
      },
      {
        fieldName: "DeviceHealth",
        fieldValue:
          this.getFieldValue("additionalInfo") +
        
          this.getFieldValue("dateofBirth") +
          
          this.getFieldValue("PassportNumber")
      },
      {
        fieldName: "FirstName",
        fieldValue: this.getFieldValue("FirstName"),
      },
      {
        fieldName: "Surname",
        fieldValue: this.getFieldValue("Surname"),
      },
      {
        fieldName: "AppUrl",
        fieldValue: `${utils.urls.MODIFY_APPOINTMENT}${localStorage.getItem(
          "lang"
        )}`,
      },
    ];
    return paylaods;
  };
  render() {
    localStorage.setItem(
      "FullName",
      this.getFieldValue("FirstName") + " " + this.getFieldValue("Surname")
    );
    var { category, branch, timeSlot, translations } = this.props;
    return (
      <>
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="user-details-container">
                <Info
                  slot={
                    timeSlot !== null &&
                    `${timeSlot.split("|")[0]} ${utils.timeFormat(
                      timeSlot.split("|")[1]
                    )}`
                  }
                  branch={branch !== null && branch}
                  category={category !== null && category.CategoryTitle}
                  getTranslations={translations}
                />
                <hr
                  style={{
                    margin: "10px 0px 10px 0px",
                    backgroundColor: FIELDS_BORDER_COLOR,
                    height: "1px",
                  }}
                />

                <div className="row">
                  <div className="col-md-12">
                    <p
                      className="step-info-text"
                      style={{ color: FONT_COLOR, marginTop: "20px" }}
                    >
                      {translations.UserDetailsInfoText}
                    </p>
                  </div>
                </div>
                <div className="user-input-container">
                  {controls.map((c, i) => {
                    if (c.type === "select") {
                      return (
                        <div className="form-group" style={{ padding: "5px" }}>
                          <label
                            className="font-ghotam"
                            style={{ fontSize: "9px" }}
                          >
                            {c.labelName}
                          </label>

                          <select
                            className="form-control font-roboto combo-box"
                            id={c.id}
                            defaultValue={-1}
                            onChange={this.handleControlChange}
                            style={{
                              border: "1px solid #d8d8d8",
                              borderRadius: "0px 0px 5px 5px",
                              outline: "none",
                              height: "35px",
                              fontSize: "12px",
                              color: FONT_COLOR,
                            }}
                          >
                            <option
                              value={-1}
                              disabled
                              className="disabled-option"
                              style={{ color: "#d8d8d8" }}
                            >
                              {c.labelName}
                            </option>

                            {c.options.map((data, i) => (
                              <option
                                key={i}
                                value={`${data.value}`}
                                style={{ color: FONT_COLOR }}
                              >
                                {data.text}
                              </option>
                            ))}
                          </select>
                        </div>
                      );
                    } else if (c.type === "textarea") {
                      return (
                        <div className="form-group" key={i}>
                          <label
                            style={{
                              color: c.isValid === false ? "red" : LABEL_COLOR,
                            }}
                            className="user-input-labels"
                          >
                            {c.labelName}
                            {c.isMandatory === true ? "*" : ""}
                          </label>
                          <textarea
                            name={c.name}
                            rows="5"
                            columns="5"
                            className={`form-control textarea-input ${
                              c.isValid === false ? "invalid-input" : ""
                            }`}
                            placeholder={c.placeholder}
                            value={c.value === null ? "" : c.value}
                            minLength={c.minLength}
                            maxLength={c.maxLength}
                            id={c.id}
                            style={{
                              border: `1px solid ${FIELDS_BORDER_COLOR}`,
                              boxShadow: "none",
                              fontSize: "12px",
                              color: FONT_COLOR,
                            }}
                            onChange={this.handleControlChange}
                          >
                            {c.value}
                          </textarea>
                        </div>
                      );
                    } else if (c.type === "date") {
                      return (
                        <div className="form-group" key={i}>
                          <label
                            style={{
                              color: c.isValid === false ? "red" : LABEL_COLOR,
                            }}
                            className="user-input-labels"
                          >
                            {c.labelName}
                            {c.isMandatory === true ? "*" : ""}
                          </label>
                          <input
                            type={c.type}
                            name={c.name}
                            className={`form-control user-input-field ${
                              c.isValid === false && c.value !== null
                                ? "invalid-input"
                                : ""
                            }`}
                            value={c.value === null ? "" : c.value}
                            min={c.minLength}
                            max={c.maxLength}
                            placeholder={c.placeholder}
                            id={c.id}
                            onChange={this.handleControlChange}
                            style={{
                              border: `1px solid ${FIELDS_BORDER_COLOR}`,
                              fontSize: "12px",
                              color: FONT_COLOR,
                              disabled: c.isdisabled,
                            }}
                          />
                        </div>
                      );
                    }else {
                      return (
                        <div className="form-group" key={i}>
                          <label
                            style={{
                              color: c.isValid === false ? "red" : LABEL_COLOR,
                            }}
                            className="user-input-labels"
                          >
                            {c.labelName}
                            {c.isMandatory === true ? "*" : ""}
                          </label>
                          <input
                            type={c.type}
                            name={c.name}
                            className={`form-control user-input-field ${
                              c.isValid === false && c.value !== null
                                ? "invalid-input"
                                : ""
                            }`}
                            value={c.value === null ? "" : c.value}
                            minLength={c.minLength}
                            maxLength={c.maxLength}
                            placeholder={c.placeholder}
                            id={c.id}
                            onChange={this.handleControlChange}
                            style={{
                              border: `1px solid ${FIELDS_BORDER_COLOR}`,
                              fontSize: "12px",
                              color: FONT_COLOR,
                              disabled: c.isdisabled,
                            }}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
                <RowFlexContainer>
                  <SecondaryButton
                    color={CLEAR_BTN_TEXT_COLOR}
                    bgColor={CLEAR_BTN_COLOR}
                    btnText={translations.ClearForm}
                    onClick={() => utils.redirect(window.location.href)}
                  />
                </RowFlexContainer>
                {secondaryControls.map((sc, i) => {
                  return (
                    <div className="user-checkbox-container" key={i}>
                      <label>
                        <input
                          type={sc.type}
                          id={sc.id}
                          className="check-box"
                          defaultChecked={sc.isChecked}
                          onChange={this.handleCheckBoxChange}
                        />
                        <span>
                          <a
                            href={sc.href}
                            target="_blank"
                            style={{
                              fontSize: "12px",
                              color: CHECKBOX_LABEL_COLOR,
                            }}
                            className="checkbox-label"
                          >
                            {sc.labelName}
                          </a>
                        </span>
                      </label>
                    </div>
                  );
                })}

                <RowFlexContainer>
                  <PrimaryButton
                    bgColor={
                      this.state.isValidFields === true &&
                      this.state.isValidSecondaryControls === true
                        ? BTN_COLOR
                        : BTN_DISABLED_COLOR
                    }
                    fontColor={
                      this.state.isValidFields === true &&
                      this.state.isValidSecondaryControls === true
                        ? BTN_TEXT_COLOR
                        : BTN_DISABLED_TEXT_COLOR
                    }
                    btnText={translations.ScheduleAppointmentBtnText}
                    onClick={
                      this.state.dataLoaded === false
                        ? null
                        : this.props.isReschedule === true
                        ? this.rescheduleAppointment
                        : this.issueAppointment
                    }
                    disabled={
                      this.state.isValidFields === true &&
                      this.state.isValidSecondaryControls === true
                        ? false
                        : true
                    }
                    showLoader={!this.state.dataLoaded}
                  />
                </RowFlexContainer>
                <RowFlexContainer>
                  <PrimaryButton
                    bgColor={RETURN_BTN_COLOR}
                    fontColor={BTN_TEXT_COLOR}
                    btnText={translations.Return}
                    onClick={() => utils.redirect()}
                    disabled={false}
                    showLoader={false}
                  />
                </RowFlexContainer>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default UserDetails;
