import axios from "axios";
const queryString = require("query-string");

let {
  DEFAULT_LANGUAGE,
  CENTRAL_SERVER_COMMUNICATION,
  REQUEST_TIMEOUT,
  REGION_ID,
  IS_LOBBY_LEADER,
  URLs: { API_URL, MESSAGING_SERVER_URL, CENTRAL_SERVER_IP, QR_TICKET_URL },
} = window["app-config"].APPLICATION_PROPERTIES;
let API_URLs;
let MESSAGING_SERVER_URLs;
let CENTRAL_SERVER_IPs;
let QR_TICKET_URLs;
export class HttpService {
  queryStringBranchCode = queryString.parse(window.location.search);
  branchCode = this.queryStringBranchCode.branchCode;

  queryStringlang = queryString.parse(window.location.search);
  qsSqn = queryString.parse(window.location.search).sqn;
  lang =
    this.queryStringlang.lang === undefined
      ? DEFAULT_LANGUAGE
      : this.queryStringlang.lang;

  /* GET REQUEST */
  getDeviceDetails = async () => {
 
    let url = `${API_URLs}PhoneCustomInfo`;
    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: null,
        },
        { timeout: REQUEST_TIMEOUT }
      );
      return response.data;
    } else {
      let response = axios.get(url);
      return response;
    }
  };

  SetConfig = (data) => {
    API_URLs = data?.data?.APPLICATION_PROPERTIES?.URLs?.API_URL;
    MESSAGING_SERVER_URLs =
      data?.data?.APPLICATION_PROPERTIES?.URLs?.MESSAGING_SERVER_URL;
    CENTRAL_SERVER_IPs =
      data?.data?.APPLICATION_PROPERTIES?.URLs?.CENTRAL_SERVER_IP;
    QR_TICKET_URLs = data?.data?.APPLICATION_PROPERTIES?.URLs?.QR_TICKET_URL;
  };
  getAllCategories = async () => {
    let url = `${API_URLs}GetCategories`;
    if (this.qsSqn === undefined) {
      if (CENTRAL_SERVER_COMMUNICATION === false) {
        let response = await axios.post(
          MESSAGING_SERVER_URLs,
          {
            payload: url,
            centralServerIp: CENTRAL_SERVER_IPs,
            body: null,
          },
          { timeout: REQUEST_TIMEOUT }
        );
        return response.data;
      } else {
        let response = axios.get(url);
        return response;
      }
    }
  };

  getCategoriesByBranchId = async (branchId) => {
    let url = `${API_URLs}Categories/${branchId}|${
      this.lang === "es" ? "Sp" : this.lang
    }`;
    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: null,
        },
        { timeout: REQUEST_TIMEOUT }
      );
      return response.data;
    } else {
      let response = axios.get(url);
      return response;
    }
  };

  getAllBranches = async () => {
    let url = `${API_URLs}/GetBranchList/2|${
      this.lang === "es" ? "Sp" : this.lang
    }|${REGION_ID}`;
    if (
      CENTRAL_SERVER_COMMUNICATION === false &&
      this.branchCode === undefined
    ) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: null,
        },
        { timeout: REQUEST_TIMEOUT }
      );
      return response.data;
    } else if (this.branchCode === undefined) {
      let response = axios.get(url, { timeout: REQUEST_TIMEOUT });
      return response;
    }
  };

  getSingleBranchDetails = async () => {
    let url = `${API_URLs}GetSingleBranchDetails/2|${
      this.lang === "es" ? "Sp" : this.lang
    }|branch_code=${this.branchCode}`;

    if (
      CENTRAL_SERVER_COMMUNICATION === false &&
      this.branchCode !== undefined
    ) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: null,
        },
        { timeout: REQUEST_TIMEOUT }
      );
      return response.data;
    } else if (this.branchCode !== undefined) {
      let response = axios.get(url);
      return response;
    }
  };

  getBranchesByCategory = async (categoryId) => {
    let url = `${API_URLs}/GetBranchListByCategory/2|${categoryId}|${
      this.lang === "es" ? "Sp" : this.lang
    }|${REGION_ID}`;

    if (
      CENTRAL_SERVER_COMMUNICATION === false &&
      this.branchCode === undefined
    ) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: null,
        },
        { timeout: REQUEST_TIMEOUT }
      );
      return response.data;
    } else {
      let response = axios.get(url, { timeout: REQUEST_TIMEOUT });
      return response;
    }
  };

  cancelAppointment = async (
    AppointmentNumber,
    categoryId,
    BranchId,
    Email
  ) => {
    let url = `${API_URLs}CancelAppointmentTicket/${AppointmentNumber}|${categoryId}|${BranchId}|${Email}|${
      this.lang === "es" ? "Sp" : this.lang
    }`;
    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: null,
        },
        { timeout: REQUEST_TIMEOUT }
      );
      return response.data;
    } else {
      let response = axios.get(url);
      console.log(
        "APPT NO: ",
        AppointmentNumber,
        " Category: ",
        categoryId,
        " Branch: ",
        BranchId,
        " Email: ",
        Email
      );
      return response;
    }
  };

  issueTicket = async (
    branchId,
    categoryId,
    priority,
    email,
    deviceType,
    language,
    Payload1,
    Payload2,
    Payload3,
    Payload4,
    Payload7
  ) => {
    let url = `${API_URLs}issueTicket/${branchId}|${categoryId}|${priority}|${email}|${deviceType}|-|-|${language}|-|${Payload1}|${Payload2}|${Payload3}|${Payload4}|-|${language}|${Payload7}`;
    console.log(
      "DATA : ",
      branchId,
      categoryId,
      priority,
      email,
      deviceType,
      " PAYLOADS : ",
      {
        P1: Payload1,
        P2: Payload2,
        P3: Payload3,
        P4: Payload4,
        P7: Payload7,
      }
    );
    console.log("ISSUE TICKET URL", url);
    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: null,
        },
        { timeout: REQUEST_TIMEOUT }
      );

      return response.data;
    } else {
      let response = axios.get(url);

      console.log(branchId, categoryId, priority, email, deviceType);
      return response;
    }
  };

  issueTicketQR = async (
    branchId,
    categoryId,
    prefix,
    priority,
    payload1,
    payload2,
    payload3,
    payload4,
    payload5,
    language,
    payload7,
    payload8,
    payload9,
    payload10
  ) => {
    let url = `${QR_TICKET_URLs}IssueTicketFromWebTicketing?BranchId=${branchId}&SQN=${categoryId}&prefix=${prefix}&Payload1=${payload1}&Payload2=${payload2}&Payload3=${payload3}&Payload4=${payload4}&Payload5=${payload5}&Payload6=${language}&Payload7=${payload7}&Payload8=${payload8}&Payload9=${payload9}&Payload10=${payload10}&priority=${priority}`;
    console.log("ISSUE QR TICKET URL", url);
    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: null,
        },
        { timeout: REQUEST_TIMEOUT }
      );

      return response.data;
    } else {
      let response = axios.get(url);
      return response;
    }
  };

  cancelQrTicket = async (branchId, branchCode, ticketId) => {
    let url = `${QR_TICKET_URLs}CancelTicketFromWebTicketing?BranchId=${branchId}&BranchCode=${branchCode}&TicketId=${ticketId}&CancelReason=Cancel`;

    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: null,
        },
        { timeout: REQUEST_TIMEOUT }
      );
      console.log("CANCEL QR TICKET URL", url);
      return response.data;
    } else {
      let response = axios.get(url);
      return response;
    }
  };

  issueTicketSpectra = (postObject) => {
    let url = "";
    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = axios.post(url, postObject);
      return response;
    }
  };

  cancelTicket = async (
    email,
    ticketId,
    branchId,
    language,
    ticketNumber,
    SQN,
    phoneNumber
  ) => {
    let url = `${API_URLs}cancelTicket/${email}|${ticketId}|${branchId}|${language}|${ticketNumber}|${SQN}|${phoneNumber}`;
    console.log("CANCEL TICKET URL: ", url);
    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: null,
        },
        { timeout: REQUEST_TIMEOUT }
      );
      return response.data;
    } else {
      let response = axios.get(url);
      return response;
    }
  };

  markAppointmentUsedAfterTicketIssue = async (
    appointmentNumber,
    emailAddress,
    ticketNumber
  ) => {
    let url = `${API_URLs}TicketIssuedForAppointment/${appointmentNumber}|${emailAddress}|${ticketNumber}`;
    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: null,
        },
        { timeout: REQUEST_TIMEOUT }
      );
      return response.data;
    } else {
      let response = axios.get(url);
      return response;
    }
  };

  verifyAppointmentInfo = async (
    email,
    phoneNumber,
    appointmentNumber,
    branchIp
  ) => {
    let url = `${API_URLs}VerifyAppointmentInfo_Json/||${email}|${phoneNumber}|${appointmentNumber}||${branchIp}|`;
    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: null,
        },
        { timeout: REQUEST_TIMEOUT }
      );
      console.log("VERIFY APPOINTMENT URL", url);
      return response.data;
    } else {
      let response = axios.get(url);
      return response;
    }
  };

  getNearByBranches = async (Sqn, Latitude, Longitude) => {
    let url = `${API_URLs}GetNearestBranches/${Latitude}|${Longitude}|${Sqn}|${this.lang}|${REGION_ID}`;

    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: null,
        },
        { timeout: REQUEST_TIMEOUT }
      );
      return response.data;
    } else {
      let response = axios.get(url);
      return response;
    }
  };

  /* POST REQUEST */

  getDisabledAppointmentDates = async (
    BranchID,
    CategoryID,
    FutureDaysForAppointment
  ) => {
    let url = `${API_URLs}GetDisabledDates/${BranchID}|${CategoryID}|${FutureDaysForAppointment}`;

    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: {
            BranchID: BranchID,
            CategoryID: CategoryID,
          },
        },
        { timeout: REQUEST_TIMEOUT }
      );
      return response.data;
    } else {
      let response = axios.get(url);
      return response;
    }
  };

  getDateTimeSlots = async (
    BranchID,
    CategoryID,
    AppointmentDate,
    AppointmentAheadDays
  ) => {
    let url = `${API_URLs}GetAllDaysSlotsOfBranch`;
    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: {
            BranchID: BranchID,
            CategoryID: CategoryID,
            AppointmentDate: AppointmentDate,
            AppointmentAHeadDays: AppointmentAheadDays,
          },
        },
        { timeout: REQUEST_TIMEOUT }
      );
      return response.data;
    } else {
      let response = axios.post(url, {
        BranchID: BranchID,
        CategoryID: CategoryID,
        AppointmentDate: AppointmentDate,
        AppointmentAHeadDays: AppointmentAheadDays,
      });
      return response;
    }
  };

  issueAppointment = async (
    CategoryID,
    BranchID,
    BranchTitle,
    BranchAddress,
    BranchContactNo,
    SlotID,
    DeviceType,
    AppointmentDate,
    UserID,
    Flag,
    IsReschedule,
    Payloads,
    Lang,
    SlotTime
  ) => {
    let url = `${API_URLs}IssueAppointmentTicket`;
    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: {
            categoryid: CategoryID,
            branchid: BranchID,
            branchTitle: BranchTitle,
            branchAddress: BranchAddress,
            branchPhoneNumber: BranchContactNo,
            slotid: SlotID,
            deviceType: DeviceType,
            appointmentDate: AppointmentDate,
            userId: UserID,
            flag: Flag,
            isReschedule: IsReschedule,
            payloads: Payloads,
            lang: Lang,
            slotTime: SlotTime,
          },
        },
        { timeout: REQUEST_TIMEOUT }
      );
      return response.data;
    } else {
      let response = axios.post(url, {
        categoryid: CategoryID,
        branchid: BranchID,
        branchTitle: BranchTitle,
        branchAddress: BranchAddress,
        branchPhoneNumber: BranchContactNo,
        slotid: SlotID,
        deviceType: DeviceType,
        appointmentDate: AppointmentDate,
        userId: UserID,
        flag: Flag,
        isReschedule: IsReschedule,
        payloads: Payloads,
        lang: Lang,
        slotTime: SlotTime,
      });
      return response;
    }
  };

  rescheduleAppointment = async (
    PrevAppointmentNumber,
    PreviousApptCategoryId,
    CategoryID,
    BranchID,
    BranchTitle,
    BranchAddress,
    BranchContactNo,
    SlotID,
    DeviceType,
    AppointmentDate,
    UserID,
    Flag,
    IsReschedule,
    Payloads,
    Lang,
    SlotTime
  ) => {
    let url = `${API_URLs}RescheduleAppointmentTicket`;
    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: {
            prevAppointmentNumber: PrevAppointmentNumber,
            previousApptCategoryId: PreviousApptCategoryId,
            categoryid: CategoryID,
            branchid: BranchID,
            branchTitle: BranchTitle,
            branchAddress: BranchAddress,
            branchPhoneNumber: BranchContactNo,
            slotid: SlotID,
            deviceType: DeviceType,
            appointmentDate: AppointmentDate,
            userId: UserID,
            flag: Flag,
            isReschedule: IsReschedule,
            payloads: Payloads,
            lang: Lang,
            slotTime: SlotTime,
          },
        },
        { timeout: REQUEST_TIMEOUT }
      );
      return response.data;
    } else {
      let response = axios.post(url, {
        prevAppointmentNumber: PrevAppointmentNumber,
        previousApptCategoryId: PreviousApptCategoryId,
        categoryid: CategoryID,
        branchid: BranchID,
        branchTitle: BranchTitle,
        branchAddress: BranchAddress,
        branchPhoneNumber: BranchContactNo,
        slotid: SlotID,
        deviceType: DeviceType,
        appointmentDate: AppointmentDate,
        userId: UserID,
        flag: Flag,
        isReschedule: IsReschedule,
        payloads: Payloads,
        lang: Lang,
        slotTime: SlotTime,
      });
      return response;
    }
  };

  getTicketStatus = async (branchId, ticketId, ticketNumber, categoryId) => {
    let url = `${API_URLs}GetTicketStatus`;
    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: {
            branch_id: branchId,
            ticket_id: ticketId,
            ticket_number: ticketNumber,
            category_id: categoryId,
            is_lobby_leader: IS_LOBBY_LEADER,
          },
        },
        { timeout: REQUEST_TIMEOUT }
      );
      return response.data;
    } else {
      let response = axios.post(url, {
        branch_id: branchId,
        ticket_id: ticketId,
        ticket_number: ticketNumber,
        category_id: categoryId,
        is_lobby_leader: IS_LOBBY_LEADER,
      });

      return response;
    }
  };

  getAppointmentInfo = async (appointmentNumber, email, phoneNumber) => {
    let url = `${API_URLs}GetAppointmentInfo`;
    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: {
            appointment_number: appointmentNumber,
            email: email,
            cell_number: phoneNumber,
          },
        },
        { timeout: REQUEST_TIMEOUT }
      );
      return response.data;
    } else {
      let response = axios.post(url, {
        appointment_number: appointmentNumber,
        email: email,
        cell_number: phoneNumber,
      });
      return response;
    }
  };

  getEstimatedWaitTime = async (branchId, Sqn) => {
    let url = `${API_URLs}GetEstWaitTime`;
    if (CENTRAL_SERVER_COMMUNICATION === false) {
      let response = await axios.post(
        MESSAGING_SERVER_URLs,
        {
          payload: url,
          centralServerIp: CENTRAL_SERVER_IPs,
          body: {
            BranchId: branchId,
            SQN: Sqn,
          },
        },
        { timeout: REQUEST_TIMEOUT }
      );
      return response.data;
    } else {
      let response = axios.post(url, {
        BranchId: branchId,
        SQN: Sqn,
      });
      return response;
    }
  };
}
