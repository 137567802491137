import React from "react";

const DetailsRow = ({ label = "", data = "", fontColor = "#000" }) => {
  return (
    <div
      className="info-text appointment-details"
      style={{ marginTop: "12px" }}
    >
      <div className="confirmation-row">
        <div className="confirmation-label" style={{ color: fontColor }}>
          {label}
        </div>
        <div className="confirmation-info" style={{ color: fontColor }}>
          {data}
        </div>
      </div>
    </div>
  );
};

export default DetailsRow;
