import React, { Component } from "react";

import { HttpService } from "./../httpServices/httpService";
import swal from "sweetalert";
import moment from "moment";
import * as utils from "../utills/utils";
import RowFlexContainer from "./../components/RowFlexContainer";
import PrimaryButton from "./../components/PrimaryButton";

const queryString = require("query-string");

let {
  CONFIRM_SCREEN: {
    FONT_COLOR,
    MODIFY_BTN_COLOR,
    CALENDAR_BTN_COLOR,
    BTN_TEXT_COLOR,
    PRIMARY_FONT_COLOR,
  },
  ICONS: { OK_ICON },
} = window["theme"].THEME;
let {
  URLs: { ICS_FILE_URL, WIZARD_URL },
} = window["app-config"].APPLICATION_PROPERTIES;

let httpService = new HttpService();

class Confirm extends Component {
  state = {};

  getFieldValue = (fieldName) => {
    let control = this.props?.userDetails.find(
      (c) => c.fieldName === fieldName
    );
    if (control === undefined || control.value === null) {
      return "";
    } else {
      return control.value;
    }
  };
  
  rescheduleOrCancelAppointment = async () => {
    var { translations, response, branch, category, modifyAppointment } =
      this.props;
    swal(
      translations.DoYouWantToRescheduleOrCancel,

      {
        icon: "warning",
        buttons: {
          reschedule: {
            text: translations.Reschedule,
            value: "reschedule",
          },
          cancelAppointment: {
            text: translations.CancelAppointment,
            value: "cancelAppointment",
            closeModal: false,
          },
        },
      }
    ).then(async (value) => {
      switch (value) {
        case "cancelAppointment":
          try {
            let cancelAptResponse = await httpService.cancelAppointment(
              response.previous_appointment_info !== null
                ? response.previous_appointment_info.appointment_number
                : response.issueAppointment.appointment_number,
              response.previous_appointment_info !== null
                ? response.previous_appointment_info.category_id
                : category.CategoryID,
              branch.BranchID,
              response.previous_appointment_info !== null
                ? response.previous_appointment_info.email_id
                : this.getFieldValue("EmailId") !== ""
                ? this.getFieldValue("EmailId")
                : this.getFieldValue("MobileNumber") !== ""
                ? this.getFieldValue("MobileNumber")
                : ""
            );
            swal.close();

            if (
              cancelAptResponse.data.error !== undefined &&
              cancelAptResponse.data.error === true
            ) {
              utils.showErrorMessage(
                translations.Error,
                translations.ServerNotResponding,
                translations.Ok
              );
            } else {
              if (cancelAptResponse.data.response.resp_status === 1) {
                utils.redirect();
              } else if (cancelAptResponse.data.response.resp_status === 6) {
                utils.showErrorMessage(
                  translations.Error,
                  translations.AppointmentAlreadyCanceled,
                  translations.Ok,
                );
              } else if (cancelAptResponse.data.response.resp_status === -5) {
                utils.showErrorMessage(
                  translations.Error,
                  translations.AppointmentInfoNotValidToCancel,
                  translations.Ok,
                );
              } else if (cancelAptResponse.data.response.resp_status === 2) {
                utils.showErrorMessage(
                  translations.Error,
                  translations.CantCancelApptTcktIssued,
                  translations.Ok,
                );
              } else if (cancelAptResponse.data.response.resp_status === 7) {
                utils.showErrorMessage(
                  translations.Error,
                  translations.AppointmentExpired,
                  translations.Ok,
                  
                );
              } else if (cancelAptResponse.data.response.resp_status === -2) {
                utils.showErrorMessage(
                  translations.Error,
                  translations.AlreadyServed,
                  translations.Ok,
                );
              } else if (cancelAptResponse.data.response.resp_status === -3) {
                utils.showErrorMessage(
                  translations.Error,
                  translations.ApptCanceledAutomatically,
                  translations.Ok,
                );
              } else {
                utils.showErrorMessage(
                  translations.Error,
                  translations.SomeThingWentWrong,
                  translations.Ok,
                );
              }
            }
          } catch (error) {
            console.log("ERROR IN CANCEL APT: ", error.message);
            if (error.message.includes("timeout")) {
              utils.showErrorMessage(
                translations.Error,
                translations.ServerNotResponding,
                translations.Ok
              );
            } else {
              utils.showErrorMessage(
                translations.Error,
                translations.SomeThingWentWrong,
                translations.Ok
              );
            }
          }
          break;

        case "reschedule":
          modifyAppointment();
          break;

        default:
      }
    });
  };
  redirectToWizard = async () => {
    //window.location.href = URLs.WIZARD_URLs;
    utils.redirect();
  };

  render() {
    var { response, translations, timeSlot, category, branch,userDetails } = this.props;
    let appointmentNumber = "";
    let appointmentDate = "";
    if (response.previous_appointment_info !== null) {
      appointmentNumber = response.previous_appointment_info.appointment_number;
      appointmentDate = moment(
        response.previous_appointment_info.appointment_date
      ).format("YYYY-MM-DD");
    } else {
      appointmentNumber = response.issueAppointment.appointment_number;
      appointmentDate = moment(timeSlot.split("|")[0], "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      );
    }
    let FullName = localStorage.getItem("FullName");
    
    
    return (
      <>
        <center>
          <div
            className="img-container"
            style={{
              width: "100px",
              backgroundColor: "#fff",
              borderRadius: "100px",
              height: "100px",
              marginTop: "50px",
              overflow: "hidden",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + `/webticketing/images/${OK_ICON}`}
            />
          </div>
          <h1
            className="main-heading font-ghotam "
            style={{ color: FONT_COLOR }}
          >
            {FullName}
          </h1>
          <h1
            className="main-heading font-ghotam "
            style={{ color: FONT_COLOR }}
          >
            {translations.YourAppointmentIsBooked}
          </h1>

          <p
            style={{ fontSize: "12px", color: FONT_COLOR }}
            className="confirm-para-msg-width"
          >
            {translations.EmailSentMsg}
          </p>

          <p
            style={{ fontSize: "15px", color: FONT_COLOR }}
            className="no-margin no-padding"
          >
            {translations.GoToBranch}
          </p>

          <h1
            className="roboto-bold-text no-margin no-padding"
            style={{ fontSize: "19px", color: PRIMARY_FONT_COLOR }}
          >
            {response.previous_appointment_info !== null
              ? `${moment(
                  response.previous_appointment_info.appointment_date
                ).format("DD-MM-YYYY")} ${
                  translations.AtThe
                } ${utils.timeFormat(
                  response.previous_appointment_info.appointment_time
                    .split("-")[0]
                    .slice(0, 5)
                )}`
              : timeSlot !== null &&
                `${timeSlot.split("|")[0]} ${
                  translations.AtThe
                } ${utils.timeFormat(timeSlot.split("|")[1])}`}
          </h1>

          <p
            style={{ fontSize: "15px", color: FONT_COLOR }}
            className="confirm-para-msg-width"
          >
            {translations.ConfirmDirectionMsg}
          </p>

          <h1
            className="roboto-bold-text no-margin no-padding"
            style={{ fontSize: "19px", color: PRIMARY_FONT_COLOR }}
          >
            {response.previous_appointment_info !== null
              ? response.previous_appointment_info.appointment_number
              : response.issueAppointment.appointment_number}
          </h1>

          <p style={{ fontSize: "15px", color: FONT_COLOR }}>
            {translations.ToTheBranchExec}
          </p>
        </center>

        <div
          className="container confirm-container"
          style={{
            width: "60%",
            margin: "0 auto",
            padding: "24px",
            backgroundColor: "#fff",
          }}
        >
          <div
            className="info-text appointment-details"
            style={{ marginTop: "12px" }}
          >
            <div className="confirmation-row">
              <div className="confirmation-label" style={{ color: FONT_COLOR }}>
                {translations.Operation}
              </div>
              <div className="confirmation-info" style={{ color: FONT_COLOR }}>
                {response.previous_appointment_info !== null
                  ? response.previous_appointment_info.category_title
                  : category.CategoryTitle}
              </div>
            </div>

            <div className="confirmation-row">
              <div className="confirmation-label" style={{ color: FONT_COLOR }}>
                {translations.BranchOffice}
              </div>
              <div className="confirmation-info" style={{ color: FONT_COLOR }}>
                {branch !== null && `${branch.BranchTitle}, ${branch.Address}`}
              </div>
            </div>

            <div className="confirmation-row">
              <div className="confirmation-label" style={{ color: FONT_COLOR }}>
                {translations.ConfirmEmailLabel}
              </div>
              <div className="confirmation-info" style={{ color: FONT_COLOR }}>
                {response.previous_appointment_info !== null
                  ? response.previous_appointment_info.email_id
                  : this.getFieldValue("EmailId") !== ''
                  ? this.getFieldValue("EmailId")
                  :"-"}
              </div>
            </div>

            <div className="confirmation-row">
              <div className="confirmation-label" style={{ color: FONT_COLOR }}>
                {translations.ConfirmMobileNumber}
              </div>
              <div className="confirmation-info" style={{ color: FONT_COLOR }}>
                {response.previous_appointment_info !== null
                  ? response.previous_appointment_info.mobile_number
                  : this.getFieldValue("MobileNumber")}
              </div>
            </div>

            <div className="confirmation-row">
              <div className="confirmation-label" style={{ color: FONT_COLOR }}>
                {translations.Note}
              </div>
              <div
                className="confirmation-info"
                style={{ color: FONT_COLOR, wordBreak: "break-all" }}
              >
                {userDetails[6].value!== null 
                ?userDetails[6].value
                :this.getFieldValue("DeviceHealth") !== null &&
                this.getFieldValue("DeviceHealth") !== ""
                  ? this.getFieldValue("DeviceHealth")
                  : "-"}
              </div>
            </div>
          </div>
        </div>

        <RowFlexContainer>
          <PrimaryButton
            onClick={this.rescheduleOrCancelAppointment}
            btnText={translations.ModifyOrCancel}
            bgColor={MODIFY_BTN_COLOR}
            fontColor={BTN_TEXT_COLOR}
          />
          &emsp;
          <PrimaryButton
            onClick={this.redirectToWizard}
            btnText={translations.Finalize}
            bgColor={MODIFY_BTN_COLOR}
            fontColor={BTN_TEXT_COLOR}
          />
        </RowFlexContainer>
        {/* <RowFlexContainer>
          <div
            style={{
              color: CALENDAR_BTN_COLOR,
            }}
          >
            <a
              href={`${ICS_FILE_URL}${this.getFieldValue(
                "EmailId"
              )}_${appointmentNumber}_${appointmentDate}_${
                branch.BranchID
              }.ics`}
              target="_blank"
              download
              className="btn btn-lg btn-info link btn-font"
              style={{
                height: "50px",
                width: "275px",
                backgroundColor: CALENDAR_BTN_COLOR,
                outline: "none",
                border: "none",
                fontSize: "17px",
                color: BTN_TEXT_COLOR,
                paddingTop: "13px",
                borderRadius: "0px",
              }}
            >
              {translations.AddToCalendar}
            </a>
          </div>
        </RowFlexContainer> */}
      </>
    );
  }
}

export default Confirm;
