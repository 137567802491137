import React from "react";
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";

let {
  LOADER: { COLOR },
} = window["theme"].THEME;

const override = css`
  display: block;
  margin: 0 auto;
  height: 200;
`;
const Loader = ({ loading = true }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <FadeLoader
        color={COLOR}
        loading={loading}
        css={override}
        size={100}
        height={20}
        margin={10}
      />
    </div>
  );
};

export default Loader;
