import React, { Component } from "react";
import { HttpService } from "./../httpServices/httpService";

import swal from "@sweetalert/with-react";
import GoogleMapReact from "google-map-react";
import Bowser from "bowser";
import Loader from "../components/Loader";
import MapMarker from "./../components/MapMarker";
import * as utils from "../utills/utils";

import { CustomRadio } from "./../components/CustomRadio/CustomRadio";

const queryString = require("query-string");

const {
  MAP_ZOOM,
  MODE,
  MAP_CENTER: { LAT, LNG },
} = window["app-config"].APPLICATION_PROPERTIES;
const {
  BRANCH_SCREEN: {
    FONT_COLOR,
    BORDER_COLOR,
    ENABLE_MAP,
    GET_NEAREST_BRANCHES,
    GET_TOP_BRANCHES,
  },
  SLOTS_SCREEN: { MIN_DATE_ARRAY },
} = window["theme"].THEME;


const queryStringBranchCode = queryString.parse(
  window.location.search
).branchCode;

const _LAT = queryString.parse(window.location.search).lat;

const _LNG = queryString.parse(window.location.search).lng;

const _ENABLEMAP = queryString.parse(window.location.search).enableMap;

const _GET_NEAREST_BRANCHES = queryString.parse(
  window.location.search
).getNearestBranches;

let httpService = new HttpService();

class Branch extends Component {
  state = {
    branches: [],
    filteredBranches: [],
    dataLoaded: false,
    locationAllowed: false,
    allowedLocation: null,
    selectedBranch: null,
    location: { lat: LAT, lng: LNG },
    showMap: true,
    disableDates: [],
    minDate: "",
  };

  componentDidMount() {
    (GET_NEAREST_BRANCHES || ENABLE_MAP) && utils.getUserLocation();
    this.getBranches();
    console.log('run...1');
  }

  getCurrentLatLong = () => {
    let defaultLat = LAT;
    let defaultLng = LNG;

    let curr = this;

    let browser = Bowser.getParser(window.navigator.userAgent);

    if (
      browser.getBrowserName() !== "Safari" &&
      queryStringBranchCode === undefined
    ) {
      navigator.geolocation.getCurrentPosition((pos) => {
        let location = {
          lat:
            pos.coords.latitude === null || pos.coords.latitude === undefined
              ? LAT
              : pos.coords.latitude,
          lng:
            pos.coords.longitude === null || pos.coords.longitude === undefined
              ? LNG
              : pos.coords.longitude,
        };
        curr.setState({
          setLocationAllowed: true,
          setAllowedLocation: location,
        });

        localStorage.setItem("location", JSON.stringify(location));
      });
      navigator.geolocation.watchPosition(function (error) {
        let location = {
          lat: defaultLat,
          lng: defaultLng,
        };

        if (error.code == error.PERMISSION_DENIED) {
          curr.setState({
            setLocationAllowed: false,
            setAllowedLocation: location,
          });
          localStorage.setItem("location", JSON.stringify(location));
          navigator.geolocation.clearWatch();
        }
      });
      navigator.permissions
        .query({ name: "geolocation" })
        .then(async (status) => {
          status.onchange = function () {
            if (this.state === "granted") {
              console.log("GRANTED");
            } else if (this.state === "denied") {
              console.log("DENIED");
              let location = {
                lat: defaultLat,
                lng: defaultLng,
              };

              swal({
                title: curr.props.getTranslations.Attention,
                text: curr.props.getTranslations.LocationServiceDisabled,

                icon: "info",
                buttons: {
                  ok: {
                    text: curr.props.getTranslations.Ok,
                    value: "OK",
                  },
                },
              }).then(() => {
                localStorage.setItem("location", JSON.stringify(location));
              });
            }
            console.log(
              "geolocation permission state has changed to ",
              this.state
            );
          };
        });
    } else {
      console.log("IN ELSE");
      let location = {
        lat: defaultLat,
        lng: defaultLng,
      };
      localStorage.setItem("location", JSON.stringify(location));
    }
  };

  componentDidUpdate(prevProps) {
    if (
      MODE === "sb" &&
      prevProps?.category?.CategoryID !== this?.props?.category?.CategoryID
    ) {
      this.getBranches();
    }
  }

  handleApiLoaded = (map) => {
    map.streetViewControl = true;
  };

  getBranches = async () => {
    let { category } = this?.props;
    try {
      let response;
      if (MODE === "bs") {
        console.log("BS");
        response = await httpService.getAllBranches();
      } else if (
        MODE === "sb" &&
        GET_NEAREST_BRANCHES === true &&
        localStorage.getItem("location")
      ) {
        console.log("SB > NEAREST BRANCH > LOCATION");
        response = await httpService.getNearByBranches(
          category?.CategoryID,
          JSON.parse(localStorage.getItem("location")).lat,
          JSON.parse(localStorage.getItem("location")).lng
        );
      } else if (MODE === "sb" && GET_NEAREST_BRANCHES === false  ) {
        console.log("SB > !NEAREST BRANCH");
        response = await httpService.getBranchesByCategory(category?.CategoryID) ;
      }
      if (response.data.error !== undefined && response.data.error === true) {
        this.setState({
          branches: undefined,
          filteredBranches: undefined,
          dataLoaded: true,
        });
      } else if (response.data.branchList === null) {
        this.setState({
          branches: [],
          filteredBranches: [],
          dataLoaded: true,
        });
      } else {
        this.setState({
          branches:
            GET_NEAREST_BRANCHES === true
              ? response.data.branchList.slice(0, GET_TOP_BRANCHES)
              : response.data.branchList,
          filteredBranches:
            GET_NEAREST_BRANCHES === true
              ? response.data.branchList.slice(0, GET_TOP_BRANCHES)
              : response.data.branchList,

          dataLoaded: true,
        });
      }
    } catch (error) {
      console.log("ERROR :", error);
      if (error.message.includes("timeout")) {
        this.setState({
          branches: undefined,
          filteredBranches: undefined,
          dataLoaded: true,
        });
      } else {
        this.setState({
          branches: [],
          filteredBranches: [],
          dataLoaded: true,
        });
      }
    }
  };

  filterBranch = (e) => {
    let filteredBranch = this.state.branches.filter(
      (b) =>
        b.BranchTitle.toLowerCase().includes(e.target.value.toLowerCase()) ||
        b.Address.toLowerCase().includes(e.target.value.toLowerCase()) ||
        b.BranchCode.toLowerCase().includes(e.target.value.toLowerCase())
    );

    if (!utils.validation.isNotEmpty(e.target.value)) {
      this.setState({ filteredBranches: this.state.branches, showMap: true });
    } else {
      this.setState({ filteredBranches: filteredBranch, showMap: false });
    }
  };

  onBranchSelect = (selectedBranch) => {
    console.log("SELECTED BRANCH: ", selectedBranch);

    this.setState({ selectedBranch });
    this.props.setBranchForParent(selectedBranch);
  };

  onMapMarkerClick = async (selectedBranch) => {
    console.log("SELECTED BRANCH MAP: ", selectedBranch);

    const action = await swal(
      <div>
        <h3 style={{ color: FONT_COLOR }}>{selectedBranch.BranchTitle}</h3>
        <strong style={{ color: FONT_COLOR }}>
          {this.props.getTranslations.Address}
        </strong>
        <p style={{ color: FONT_COLOR }}>{selectedBranch.Address}</p>
      </div>,

      {
        icon: "info",

        buttons: {
          yes: {
            text: this.props.getTranslations.Next,
            value: "Yes",
          },
          no: {
            text: this.props.getTranslations.Close,
            value: "No",
          },
        },
      }
    );
    if (action === "Yes") {
      this.props.setBranchForParent(selectedBranch);
    }
  };

  render() {
    console.log('props...', this.props);
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <p className="step-info-text" style={{ color: FONT_COLOR }}>
              {this.props.getTranslations.BranchInfoText}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <label className="control-label"></label>
            <div className="inner-addon right-addon">
              <i
                className="glyphicon glyphicon-search"
                style={{ color: "#b3b3b3" }}
              ></i>
              <input
                type="text"
                className="form-control searchField"
                placeholder={this.props.getTranslations.SearchBranchPlaceHolder}
                onChange={this.filterBranch}
              />
            </div>
          </div>
        </div>

        {!this.state.dataLoaded ? (
          <div className="row">
            <div className="col-md-12">
              <Loader />
            </div>
          </div>
        ) : (
          <>
            {
              //_ENABLEMAP === "true"
              ENABLE_MAP === true && this.state.showMap && (
                <div className="row">
                  <div className="col-md-12">
                    <div
                      style={{
                        height: "50vh",
                        width: "auto",
                        marginTop: "10px",
                        marginBottom: "10px",
                        padding: "10px 0px 10px 0px",
                      }}
                    >
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: "AIzaSyA85oYpaPSkYFRM8d4QcWMg4ZmH8Frnf8c",
                          language: localStorage.getItem("lang"),
                        }}
                        defaultCenter={
                          localStorage.getItem("location") &&
                          JSON.parse(localStorage.getItem("location"))

                          //mapLocation
                        }
                        initialCenter={
                          localStorage.getItem("location") &&
                          JSON.parse(localStorage.getItem("location"))
                          //mapLocation
                        }
                        defaultZoom={MAP_ZOOM}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) =>
                          this.handleApiLoaded(map, maps)
                        }
                        draggable={true}
                      >
                        {this?.state?.branches?.length > 0
                          ? this?.state?.branches?.map((data, i) => {
                              return (
                                <MapMarker
                                  key={i}
                                  lat={data.Latitude}
                                  lng={data.Longitude}
                                  BranchTitle={data.BranchTitle}
                                  BranchCode={data.BranchCode}
                                  BranchID={data.BranchID}
                                  ContactNo={data.ContactNo}
                                  FutureDaysForAppointment={
                                    data.FutureDaysForAppointment
                                  }
                                  Address={data.Address}
                                  position={{
                                    lat: data.Latitude,
                                    lng: data.Longitude,
                                  }}
                                  onClick={() => this.onMapMarkerClick(data)}
                                />
                              );
                            })
                          : null}
                      </GoogleMapReact>
                    </div>
                  </div>
                </div>
              )
            }
          </>
        )}

        <div
          className="branch-container"
          style={{
            marginTop: "15px",
            height: "250px",
            borderRadius: "10px",
            border:
              this.state.filteredBranches !== null &&
              this.state.filteredBranches.length > 0 &&
              `1px solid ${BORDER_COLOR}`,
            overflowY: "scroll",
          }}
        >
          {this?.state?.filteredBranches !== null &&
            this?.state?.filteredBranches.length > 0 &&
            this?.state?.filteredBranches.map((data, i) => (
              <React.Fragment key={i}>
                <div className="row" style={{ padding: "10px 40px" }} key={i}>
                  <CustomRadio
                    value={data}
                    selected={this?.state?.selectedBranch}
                    description={data.Address}
                    label={data.BranchTitle}
                    onChange={() => this.onBranchSelect(data)}
                    fontColor={FONT_COLOR}
                  />
                </div>
                {this?.state.filteredBranches.length > 1 && (
                  <hr style={{ borderColor: BORDER_COLOR }} />
                )}
              </React.Fragment>
            ))}{" "}
        </div>
      </>
    );
  }
}

export default Branch;
