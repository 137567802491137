import React, { useState, useEffect, Component } from "react";

import $ from "jquery";
import moment from "moment";
import { HttpService } from "./../httpServices/httpService";
import Loader from "./../components/Loader";
import * as utils from "../utills/utils";
import { Scrollbars } from "react-custom-scrollbars";
const queryString = require("query-string");
let qsSqn = queryString.parse(window.location.search).sqn;
var {
  SLOTS_SCREEN: {
    SLOTS_BG_COLOR,
    DISABLED_SLOT_OPTION,
    SLOTS_FONT_COLOR,
    MIN_DATE_ARRAY,
    DISABLED_DATES,
  },
} = window["theme"].THEME;

var MIN_DATE = "";
var disabledDate = false
let { SLOTS_TO_SHOW, DEFAULT_CATEGORY, DEFAULT_AHEAD_DAYS } =
  window["app-config"].APPLICATION_PROPERTIES;
var httpService = new HttpService();

class Slots extends Component {
  state = {
    dataLoaded: false,
    selectedDate: moment(new Date()).format("YYYY-MM-DD"),
    timeSlots: [],
    selectedTimeSlot: null,
    minDate: this.props.minDate,
    maxDate: this.props.maxDate,
  };

  componentDidMount() {
    this.onDateSelection();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.branch.BranchID !== this.props.branch.BranchID ||
      prevProps.category !== this.props.category
    ) {
      var curr = this;
      $(document).ready(function () {
        $("#calendar").datepicker();
        $("#calendar").datepicker("setDatesDisabled", curr.props.disabledDates);
        $("#calendar").datepicker("setStartDate", curr.props.minDate);
        $("#calendar").datepicker("setEndDate", curr.props.maxDate);
        $("#calendar").datepicker("setDate", curr.props.minDate);
      });
    }
  }

  onDateSelection = () => {
    // console.log("DATES DIS: ", this.props.disabledDates);
    // console.log("MIN DATE: ", this.props.minDate);
    var curr = this;
    $(document).ready(function () {
      $("#calendar").datepicker();
      $("#calendar").datepicker("setStartDate", curr.props.minDate);
      $("#calendar").datepicker("setEndDate", curr.props.maxDate);
      $("#calendar").datepicker("setDate", curr.props.minDate);
    });

    $("#calendar")
      .datepicker({
        format: "yyyy/mm/dd",
        startDate: new Date(),
        maxViewMode: 0,
        minViewMode: 0,
        datesDisabled: this.props.disabledDates,
        endDate: this.props.maxDate,

        language: localStorage.getItem("lang"),
      })
      .on("changeDate", function (e) {
        
        let getDisabledDateFromLocalStorage = localStorage.getItem("offDatesDisabledDate");
        const newDisable = JSON.parse(getDisabledDateFromLocalStorage)
        let disabledDatedCheck = null
        if(newDisable!=null){
          disabledDatedCheck = newDisable
        }

        if(disabledDatedCheck!=null){
          disabledDate = disabledDatedCheck.includes(e.format(0, "yyyy-mm-dd"));
        }

      if(disabledDate){
        curr.setState({
          selectedDate: e.format(0, "dd/mm/yyyy"),
          dataLoaded: false,
        });
        setTimeout(() => {
          curr.setState({
            dataLoaded: true
          });
        }, 500);
      }
      else{

        curr.setState({
          selectedDate: e.format(0, "dd/mm/yyyy"),
          dataLoaded: false,
        });

        curr.getAppointmentSlots(e.format(0, "yyyy/mm/dd"));
      }
      });
  };

  onTimeSlotSelect = (value) => {
    this.setState({ selectedTimeSlot: value });
    this.props.setSlotForParent(`${value.target.value}`);
  };

  getAppointmentSlots = async (date) => {
    var dateGiven = moment(date);
    var DAY = dateGiven.day();

    DAY = DAY == 0 ? 7 : DAY;

    try {
      var { branch, category } = this.props;
      var { data } = await httpService.getDateTimeSlots(
        branch !== null && branch.BranchID,
        category === null
          ? qsSqn !== undefined
            ? qsSqn
            : DEFAULT_CATEGORY
          : category.CategoryID,
        date === "" ? moment(new Date()).format("YYYY-MM-DD") : date,
        branch !== null &&
          (branch.FutureDaysForAppointment === null ||
            branch.FutureDaysForAppointment === "")
          ? DEFAULT_AHEAD_DAYS
          : Number(branch.FutureDaysForAppointment)
      );

      if (data.error !== undefined) {
        this.setState({ timeSlots: [] });
      } else if (data.appointmentSlots !== null) {
        var appointmentSlots = data.appointmentSlots.slice(
          0,
          SLOTS_TO_SHOW < 0 || SLOTS_TO_SHOW > 7 ? 7 : SLOTS_TO_SHOW
        );
        let slots = appointmentSlots[0].slotDetail;

        if (slots.length > 0) {
          this.setState({ timeSlots: slots });
        } else {
          this.setState({ timeSlots: [], dataLoaded: true });
        }

        this.setState({ dataLoaded: true });
      } else {
        this.setState({ timeSlots: null, dataLoaded: true });
      }

      var slotValue = false;

      appointmentSlots !== undefined &&
        appointmentSlots.forEach(function (value, key) {
          if (value.DayID == DAY) {
            if (value.slotDetail.length == 0) {
              slotValue = true;
            }
          }
        });
    } catch (error) {
      console.log(error.message);
      if (
        error.message.includes("timeout") ||
        error.message.includes("ERR_CONNECTION")
      ) {
        this.setState({ dataLoaded: true });
      } else {
        this.setState({ dataLoaded: false });
      }
    }
  };

  render() {
    utils.setDateTimeSlotTheme();
    var { getTranslations } = this.props;
    return (
      <div>
        <div
          className={`row cal-row ${
            !this.state.dataLoaded ? "cal-disable" : ""
          }`}
        >
          <div className="col-md-12 calendar-column">
            <div id="calendar"></div>
          </div>
        </div>
        {!this.state.dataLoaded ? (
          <div className="row">
            <div className="col-md-12">
              <Loader />
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
            {
              disabledDate ? 
              (
                <div className="form-group" style={{ padding: "5px" }}>
                <label
                  className="font-ghotam"
                  style={{ fontSize: "9px", color: SLOTS_FONT_COLOR }}
                >
                  {getTranslations.Time}*
                </label>

                <select
                  className="form-control font-roboto combo-box"
                  defaultValue={-1}
                  onChange={this.onTimeSlotSelect}
                  style={{
                    border: "1px solid #d8d8d8",
                    borderRadius: "0px 0px 5px 5px",
                    outline: "none",
                    height: "35px",
                    fontSize: "12px",
                    color:
                      this.state.selectedTimeSlot === null
                        ? DISABLED_SLOT_OPTION
                        : SLOTS_FONT_COLOR,
                  }}
                >
                  <option
                    value={-1}
                    disabled
                    className="disabled-option"
                    style={{ color: "#d8d8d8" }}
                  >
                    {getTranslations.SelectAvailableSchedule}
                  </option>
                </select>
              </div>
              )
              :
              (
              <div className="form-group" style={{ padding: "5px" }}>
                <label
                  className="font-ghotam"
                  style={{ fontSize: "9px", color: SLOTS_FONT_COLOR }}
                >
                  {getTranslations.Time}*
                </label>

                <select
                  className="form-control font-roboto combo-box"
                  defaultValue={-1}
                  onChange={this.onTimeSlotSelect}
                  style={{
                    border: "1px solid #d8d8d8",
                    borderRadius: "0px 0px 5px 5px",
                    outline: "none",
                    height: "35px",
                    fontSize: "12px",
                    color:
                      this.state.selectedTimeSlot === null
                        ? DISABLED_SLOT_OPTION
                        : SLOTS_FONT_COLOR,
                  }}
                >
                  <option
                    value={-1}
                    disabled
                    className="disabled-option"
                    style={{ color: "#d8d8d8" }}
                  >
                    {getTranslations.SelectAvailableSchedule}
                  </option>

                  {this.state.timeSlots !== null &&
                    this.state.timeSlots.length > 0 &&
                    this.state.timeSlots.map((data, i) => (
                      <option
                        key={i}
                        value={`${this.state.selectedDate}|${data.StartTime}|${data.SlotID}`}
                        style={{ color: SLOTS_FONT_COLOR }}
                      >
                        {utils.timeFormat(data.StartTime)}
                      </option>
                    ))}
                </select>
              </div>
              )

            }

            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Slots;
