import React, { Component } from "react";
import "./CustomRadio.css";

let {
  RADIO_BTN: { BORDER_COLOR, SELECTED_INNER_COLOR },
} = window["theme"].THEME;
export class CustomRadio extends Component {
  state = {};

  render() {
    const { selected, onChange, label, value, fontColor, description } =
      this.props;
    return (
      <div
        className="modern-radio-container"
        onClick={() => {
          onChange(value);
        }}
      >
        <div
          style={{
            marginTop: description && "15px",
            border: `1px solid ${BORDER_COLOR}`,
          }}
          className={`radio-outer-circle ${value !== selected && "unselected"}`}
        >
          <div
            className={`radio-inner-circle ${
              value !== selected && "unselected-circle"
            }`}
            style={{ backgroundColor: SELECTED_INNER_COLOR }}
          />
        </div>

        <p>
          <span className="radio-label" style={{ color: fontColor }}>
            {label}
          </span>
          <br />
          <span className="custom-radio-desc" style={{ color: fontColor }}>
            {description}
          </span>
        </p>
      </div>
    );
  }
}
