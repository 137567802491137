import React, { Component } from "react";
import { Container, Card, Accordion } from "react-bootstrap";

import $ from "jquery";

import UserDetails from "./UserDetails";
import Slots from "./Slots";
import Branch from "./Branch";
import Category from "./Category";

import * as utils from "../utills/utils";
import { HttpService } from "./../httpServices/httpService";
import Confirm from "./Confirm";
import Loader from "./../components/Loader";
import RowFlexContainer from "../components/RowFlexContainer";
import PrimaryButton from "./../components/PrimaryButton";

var queryString = require("query-string");

var {
  DEFAULT_LANGUAGE,
  NAV_TABS,
  SHOW_TAB_SELECTED_DATA,
  APP_ROUTE,
  URLs: { WIZARD_URL },
  MODE,
} = window["app-config"].APPLICATION_PROPERTIES;
var WIZARD_URLs;

var {
  TABS: { BODY_BG_COLOR, HEADER_BG_COLOR, FONT_COLOR, CHEV_DOWN_ICON },
  ICONS: { CALENDAR_ICON },
  MAIN_SCREEN: { BTN_TEXT_COLOR, RETURN_BTN_COLOR, FONT_COLOR },
} = window["theme"].THEME;

var qsLanguage = queryString.parse(window.location.search).lang;
var qsBranchCode = queryString.parse(window.location.search).branchCode;
var qsBranchId = queryString.parse(window.location.search).branchid;
var qsSqn = queryString.parse(window.location.search).sqn;
var qsLqn = queryString.parse(window.location.search).lqn;
var qsService = queryString.parse(window.location.search).service;
var language =
  window["localization"][qsLanguage] !== undefined
    ? window["localization"][qsLanguage]
    : window["localization"][DEFAULT_LANGUAGE];

let tabs = NAV_TABS(language);
tabs = tabs.filter((t) => {
  return t.isEnable === true;
});

var httpService = new HttpService();

class Main extends Component {
  state = {
    translations: {},
    branch: null,
    category: null,
    timeSlot: null,
    response: null,
    userDetails: null,
    isSingleBranchLoaded: false,
    isReschedule: false,
    activeTabKey: "0",
    passedAccordians: ["0"],
    appointmentIssued: false,
    disabledDates: [],
    minDate: "",
  };

  componentDidMount() {
    this.setState({ translations: utils.setTranslations() });
    utils.setDatePickerLanguage();

    if (MODE === "sb" && qsBranchCode !== undefined && qsSqn !== undefined) {
      this.expandNextAccordian("categorySelection");
      this.setState({
        category: { CategoryID: qsSqn, CategoryTitle: qsLqn },
      });
      this.expandNextAccordian("branchSelection");
      this.getSingleBranchDetails();
    } else if (
      MODE === "bs" &&
      qsBranchCode !== undefined &&
      qsSqn !== undefined
    ) {
      this.expandNextAccordian("branchSelection");
      this.getSingleBranchDetails();

      this.expandNextAccordian("categorySelection");
      this.setState({
        category: { CategoryID: qsSqn, CategoryTitle: qsLqn },
      });
    } else if (qsBranchCode !== undefined) {
      this.expandNextAccordian("branchSelection");
      this.getSingleBranchDetails();
    } else if (qsSqn !== undefined) {
      this.expandNextAccordian("categorySelection");
      this.setState({ category: { CategoryID: qsSqn, CategoryTitle: qsLqn } });
    }
  }

 
  getBranchFromChild = (branch) => {
    this.setState({ branch: branch });
    this.expandNextAccordian("branchSelection");
  };

  getCategoryFromChild = (category, minDate, disabledDates, maxDate) => {
    this.setState({
      category: category,
      minDate: minDate,
      disabledDates: disabledDates,
      maxDate: maxDate,
    });

    this.expandNextAccordian("categorySelection");
  };

  getTimeSlotFromChild = (timeSlot) => {
    this.setState({ timeSlot: timeSlot });
    this.expandNextAccordian("slotSelection");
  };

  getApiResponse = (data) => {
    this.setState({ response: data.response, userDetails: data.userDetails });
  };

  getSingleBranchDetails = async () => {
    try {
      let response = await httpService.getSingleBranchDetails();
      this.setState({
        branch: response.data.branchList[0],
        isSingleBranchLoaded: true,
      });
    } catch (error) {
      this.setState({
        branch: null,
        isSingleBranchLoaded: false,
      });
    }
  };

  renderAccordionBody = (id) => {
    switch (id) {
      case "slotSelection":
        return (
          <Slots
            getTranslations={this.state.translations}
            category={this.state.category}
            branch={this.state.branch}
            minDate={this.state.minDate}
            maxDate={this.state.maxDate}
            disabledDates={this.state.disabledDates}
            setSlotForParent={this.getTimeSlotFromChild}
          />
        );

      case "userInformation":
        return (
          <UserDetails
            translations={this.state.translations}
            category={this.state.category}
            branch={this.state.branch}
            timeSlot={this.state.timeSlot}
            setAPIResponseForParent={this.getApiResponse}
            apiReponse={this.state.response}
            language={this.state.language}
            hadleAppointmentIssue={this.hadleAppointmentIssue}
            isReschedule={this.state.isReschedule}
          />
        );

      case "branchSelection":
        return (
          <Branch
            getTranslations={this.state.translations}
            category={this.state.category}
            setBranchForParent={this.getBranchFromChild}
          />
        );

      case "categorySelection":
        return (
          <Category
            getTranslations={this.state.translations}
            branch={this.state.branch}
            setCategoryForParent={this.getCategoryFromChild}
          />
        );

      default:
        break;
    }
  };

  renderAccordianCollapse = (key, id) => {
    if (qsBranchCode !== undefined && id === "branchSelection") {
      let index = tabs.indexOf(
        tabs.find((tab) => tab.id === "branchSelection")
      );
      let nextTabKey = tabs[index + 1].tabKey;

      return (
        <Accordion.Collapse
          className="accord-body"
          style={{ backgroundColor: BODY_BG_COLOR }}
        >
          <Card.Body>{this.renderAccordionBody(id)}</Card.Body>
        </Accordion.Collapse>
      );
    } else if (qsSqn !== undefined && id === "categorySelection") {
      let index = tabs.indexOf(
        tabs.find((tab) => tab.id === "categorySelection")
      );
      let nextTabKey = tabs[index + 1].tabKey;

      return (
        <Accordion.Collapse
          className="accord-body"
          style={{ backgroundColor: BODY_BG_COLOR }}
        >
          <Card.Body>{this.renderAccordionBody(id)}</Card.Body>
        </Accordion.Collapse>
      );
    } else {
      return (
        <Accordion.Collapse
          eventKey={key}
          className="accord-body"
          style={{ backgroundColor: BODY_BG_COLOR }}
        >
          <Card.Body>{this.renderAccordionBody(id)}</Card.Body>
        </Accordion.Collapse>
      );
    }
  };

  renderAccordianText = (tab) => {
    if (tab.id === "branchSelection") {
      return (
        <>
          <div className="step-name" style={{ color: FONT_COLOR }}>
            {tab.text}

            <span
              style={{
                float: "right",
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  `/webticketing/images/${CHEV_DOWN_ICON}`
                }
                alt="logo"
              />
            </span>
          </div>

          {SHOW_TAB_SELECTED_DATA && this.state.branch !== null && (
            <p className="step-data-text" style={{ color: FONT_COLOR }}>
              {this.state.branch.BranchTitle}
            </p>
          )}
        </>
      );
    } else if (tab.id === "categorySelection") {
      return (
        <>
          <div className="step-name" style={{ color: FONT_COLOR }}>
            {tab.text}
            <span
              style={{
                float: "right",
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  `/webticketing/images/${CHEV_DOWN_ICON}`
                }
                alt="logo"
              />
            </span>
          </div>
          {SHOW_TAB_SELECTED_DATA && this.state.category !== null && (
            <p className="step-data-text" style={{ color: FONT_COLOR }}>
              {this.state.category.CategoryTitle}
            </p>
          )}
        </>
      );
    } else if (tab.id === "slotSelection") {
      return (
        <>
          <div className="step-name" style={{ color: FONT_COLOR }}>
            {tab.text}

            <span
              style={{
                float: "right",
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  `/webticketing/images/${CHEV_DOWN_ICON}`
                }
                alt="logo"
              />
            </span>
          </div>
          {SHOW_TAB_SELECTED_DATA && this.state.timeSlot !== null && (
            <p className="step-data-text" style={{ color: FONT_COLOR }}>
              {`${this.state.timeSlot.split("|")[0]} ${utils.timeFormat(
                this.state.timeSlot.split("|")[1]
              )}`}
            </p>
          )}
        </>
      );
    } else if (tab.id === "userInformation") {
      return (
        <>
          <div className="step-name" style={{ color: FONT_COLOR }}>
            {tab.text}

            <span
              style={{
                float: "right",
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  `/webticketing/images/${CHEV_DOWN_ICON}`
                }
                alt="logo"
              />
            </span>
          </div>

          {SHOW_TAB_SELECTED_DATA && (
            <p className="step-data-text" style={{ color: FONT_COLOR }}>
              {""}
            </p>
          )}
        </>
      );
    }
  };

  expandNextAccordian = (currId) => {
    let index = tabs.indexOf(tabs.find((tab) => tab.id === currId));
    this.setState({ activeTabKey: tabs[index + 1].tabKey });
    let passedAccord = [...this.state.passedAccordians];
    passedAccord.push(tabs[index + 1].tabKey);
    this.setState({ passedAccordians: passedAccord });
    $(`#${tabs[index + 1].id}`).trigger("click");
  };

  hadleAppointmentIssue = () => {
    this.setState({ appointmentIssued: true });
  };

  modifyAppointment = () => {
    this.setState({ appointmentIssued: false, isReschedule: true });
    let index = tabs.indexOf(tabs.find((tab) => tab.id === "slotSelection"));
    this.setState({ passedAccordians: [tabs[index].tabKey] });
    $(`#slotSelection`).trigger("click");
  };

  setActiveTabKey = (key) => {
    this.setState({ activeTabKey: key });
  };

  render() {
    if (qsBranchCode !== undefined && !this.state.isSingleBranchLoaded) {
      return (
        <div
          className="load"
          style={{
            marginTop: "50px",
          }}
        >
          <Loader />
        </div>
      );
    }
    if (this.state.appointmentIssued) {
      return (
        <Confirm
          translations={this.state.translations}
          branch={this.state.branch}
          category={this.state.category}
          timeSlot={this.state.timeSlot}
          userDetails={this.state.userDetails}
          response={this.state.response}
          modifyAppointment={this.modifyAppointment}
        />
      );
    } else {
      return (
        <>
          <center>
            <div
              className="img-container"
              style={{
                width: "100px",
                backgroundColor: "#fff",
                borderRadius: "100px",
                height: "100px",
                marginTop: "50px",
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  `/webticketing/images/${CALENDAR_ICON}`
                }
              />
            </div>
            <h1 className="main-heading" style={{ color: FONT_COLOR }}>
              {this.state.translations.ScheduleAppointmentHeading}
            </h1>
          </center>
          <Container>
            <div className="col-container">
              <Accordion defaultActiveKey={this.state.activeTabKey}>
                {tabs.map((tab, i) => {
                  if (
                    (qsBranchCode !== undefined &&
                      tab.id !== "branchSelection") ||
                    (qsSqn !== undefined && tab.id !== "categorySelection")
                  ) {
                    return (
                      <Card className="colapsible-card" key={i}>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey={tab.tabKey}
                          className="collapsible-header"
                          id={tab.id}
                          onClick={() => {
                            this.setActiveTabKey(tab.tabKey);
                          }}
                          style={{
                            backgroundColor: HEADER_BG_COLOR,
                            height: !SHOW_TAB_SELECTED_DATA ? "47px" : "auto",
                            padding: !SHOW_TAB_SELECTED_DATA
                              ? "3px 24px 16px 24px"
                              : "16px 24px 16px 24px",
                          }}
                        >
                          {this.renderAccordianText(tab)}
                        </Accordion.Toggle>
                        {this.state.passedAccordians.includes(tab.tabKey)
                          ? this.renderAccordianCollapse(tab.tabKey, tab.id)
                          : ""}
                      </Card>
                    );
                  } else if (
                    qsBranchCode === undefined &&
                    qsSqn === undefined
                  ) {
                    return (
                      <Card className="colapsible-card" key={i}>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey={tab.tabKey}
                          className="collapsible-header"
                          id={tab.id}
                          onClick={() => {
                            this.setActiveTabKey(tab.tabKey);
                          }}
                          style={{
                            backgroundColor: HEADER_BG_COLOR,
                            height: !SHOW_TAB_SELECTED_DATA ? "47px" : "auto",
                            padding: !SHOW_TAB_SELECTED_DATA
                              ? "3px 24px 16px 24px"
                              : "16px 24px 16px 24px",
                          }}
                        >
                          {this.renderAccordianText(tab)}
                        </Accordion.Toggle>
                        {this.state.passedAccordians.includes(tab.tabKey)
                          ? this.renderAccordianCollapse(tab.tabKey, tab.id)
                          : ""}
                      </Card>
                    );
                  }
                })}
              </Accordion>
            </div>
            {this.state.activeTabKey !== "3" && (
              <RowFlexContainer>
                <PrimaryButton
                  bgColor={RETURN_BTN_COLOR}
                  fontColor={BTN_TEXT_COLOR}
                  btnText={this.state.translations.Return}
                  onClick={() => utils.redirect()}
                  disabled={false}
                  showLoader={false}
                />
              </RowFlexContainer>
            )}
          </Container>
        </>
      );
    }
  }
}

export default Main;
