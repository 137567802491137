import React from "react";

let { MAP_MARKER } = window["theme"].THEME.BRANCH_SCREEN;
const MapMarker = ({ height = "30px", onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={process.env.PUBLIC_URL + `/webticketing/images/${MAP_MARKER}`}
        height={height}
        style={{ transform: "translate(-15px, -30px)" }}
      />

      <span></span>
    </div>
  );
};

export default MapMarker;
