import React, { Component } from "react";
import RowFlexContainer from "./../components/RowFlexContainer";
import * as utils from "../utills/utils";
import { HttpService } from "./../httpServices/httpService";
import $ from "jquery";
import swal from "sweetalert";
import DetailsRow from "../components/DetailsRow";
import PrimaryButton from "./../components/PrimaryButton";

const queryString = require("query-string");

var appointmentNumber = queryString.parse(
  window.location.search
).appointmentnumber;

var {
  REDIRECT_TO_CURRENT_PAGE,
  APP_ROUTE,
  DEFAULT_PHONE_PREFIX,
  URLs: { WIZARD_URL },
} = window["app-config"].APPLICATION_PROPERTIES;
var {
  MODIFY_APPT_SCREEN: {
    FONT_COLOR,
    BTN_FONT_COLOR,
    MODIFY_BTN_COLOR,
    SUBMIT_BTN_COLOR,
  },
} = window["theme"].THEME;

let httpService = new HttpService();

class ModifyAppointment extends Component {
  state = {
    translations: {},
    enableBtn: false,
    isInfoDataLoaded: false,
    dataLoaded: true,
    response: null,

    redirectUrl: "",

    email: null,
    appointmentNumber: null,
    phoneNumber: null,
  };

  componentDidMount() {
    this.setState({
      translations: utils.setTranslations(),
      redirectUrl: utils.removeKeyFromUrl(
        [REDIRECT_TO_CURRENT_PAGE ? "modifyappointment" : APP_ROUTE],
        "appointmentnumber"
      ),
    });
    if (appointmentNumber !== undefined && appointmentNumber !== "") {
      this.getAppointmentInfo(appointmentNumber);
    }
  }

  onChange = (e) => {
    if (e.target.id === "appointmentNumber") {
      if (
        e.target.value !== "" &&
        e.target.value.length > 0 &&
        utils.validation.isNumber(e.target.value)
      ) {
        this.setState({
          enableBtn: true,
          appointmentNumber: e.target.value,
        });
      } else {
        this.setState({ enableBtn: false });
      }
    } else if (e.target.id === "phoneNumber") {
      if (
        (e.target.value.length === 0 && e.target.value === "") ||
        /\s/.test(e.target.value) ||
        !/^[0-9+]+$/.test(e.target.value)
      ) {
        $("#phoneNumber").css("border-color", "red");
        this.setState({ enableBtn: false });
      } else {
        $("#phoneNumber").css("border-color", "#dadada");
        this.setState({ enableBtn: true, phoneNumber: e.target.value });
      }
    } else if (e.target.id === "emailAddress") {
      if (
        e.target.value !== "" &&
        e.target.value.length > 0 &&
        utils.validateEmail(e.target.value)
      ) {
        this.setState({ enableBtn: true, email: e.target.value });
      } else {
        $("#emailAddress").css("border-color", "#dadada");
        this.setState({ enableBtn: false });
      }
    }
  };

  getAppointmentInfo = async (appointmentNumber) => {
    this.setState({ dataLoaded: false });
    try {
      let response = await httpService.getAppointmentInfo(
        appointmentNumber,
        this.state.email,
        `${
          this.state.phoneNumber !== null
            ? DEFAULT_PHONE_PREFIX + this.state.phoneNumber
            : this.state.phoneNumber
        }`
      );

      if (response.data.error !== undefined && response.data.error === true) {
        utils.showErrMsg(
          this.state.translations.Error,
          this.state.translations.ServerNotResponding,
          this.state.translations.Close,
          this.state.redirectUrl
        );
        this.setState({ isInfoDataLoaded: false, dataLoaded: true });
      } else if (
        response.data.response_code === 100 ||
        response.data.response_code === 106
      ) {
        this.setState({
          response: response.data.appointment_info,
          isInfoDataLoaded: true,
          dataLoaded: true,
        });
      } else if (
        response.data.response_code === 102 &&
        response.data.message === "no appointment found."
      ) {
        utils.showErrMsg(
          this.state.translations.Error,
          this.state.translations.AppointmentNotFound,
          this.state.translations.Close,
          this.state.redirectUrl
        );

        this.setState({ isInfoDataLoaded: false, dataLoaded: true });
      } else if (response.data.response_code === 103) {
        utils.showErrMsg(
          this.state.translations.Error,
          this.state.translations.TicketAlreadyIssued,
          this.state.translations.Close,
          this.state.redirectUrl
        );
        this.setState({ isInfoDataLoaded: false, dataLoaded: true });
      } else {
        utils.showErrMsg(
          this.state.translations.Error,
          this.state.translations.SomeThingWentWrong,
          this.state.translations.Close,
          this.state.redirectUrl
        );
        this.setState({ isInfoDataLoaded: false, dataLoaded: true });
      }
    } catch (error) {
      this.setState({ isInfoDataLoaded: false, dataLoaded: true });
      console.log("ERROR: ", error.message);
    }
  };

  modifyAppointment = async () => {
    swal(
      this.state.translations.ModifyOrCancelAptMsg,

      {
        icon: "warning",
        buttons: {
          reschedule: {
            text: this.state.translations.Close,
            value: "reschedule",
          },
          cancelAppointment: {
            text: this.state.translations.CancelAppointment,
            value: "cancelAppointment",
            closeModal: false,
          },
        },
      }
    ).then(async (value) => {
      switch (value) {
        case "cancelAppointment":
          try {
            let response = await httpService.cancelAppointment(
              this.state.response.appointment_number,
              this.state.response.category_id,
              this.state.response.branch_id,
              this.state.response.user_id
            );
            swal.close();

            if (
              response.data.error !== undefined &&
              response.data.error === true
            ) {
              utils.showErrMsg(
                this.state.translations.Error,
                this.state.translations.ServerNotResponding,
                this.state.translations.Close,
                this.state.redirectUrl
              );
            } else {
              if (response.data.response.resp_status === 1) {
                utils.showSuccessMessage(
                  this.state.translations.AppointmentCancelledSuccessfully,
                  this.state.translations.Close,
                  this.state.redirectUrl
                );
              } else if (response.data.response.resp_status === 6) {
                utils.showErrMsg(
                  this.state.translations.Error,
                  this.state.translations.AppointmentAlreadyCanceled,
                  this.state.translations.Close,
                  this.state.redirectUrl
                );
              } else if (response.data.response.resp_status === -5) {
                utils.showErrMsg(
                  this.state.translations.Error,
                  this.state.translations.AppointmentInfoNotValidToCancel,
                  this.state.translations.Close,
                  this.state.redirectUrl
                );
              } else if (response.data.response.resp_status === 2) {
                utils.showErrMsg(
                  this.state.translations.Error,
                  this.state.translations.CantCancelApptTcktIssued,
                  this.state.translations.Close,
                  this.state.redirectUrl
                );
              } else if (response.data.response.resp_status === 7) {
                utils.showErrMsg(
                  this.state.translations.Error,
                  this.state.translations.AppointmentExpired,
                  this.state.translations.Close,
                  this.state.redirectUrl
                );
              } else if (response.data.response.resp_status === -2) {
                utils.showErrMsg(
                  this.state.translations.Error,
                  this.state.translations.AlreadyServed,
                  this.state.translations.Close,
                  this.state.redirectUrl
                );
              } else if (response.data.response.resp_status === -3) {
                utils.showErrMsg(
                  this.state.translations.Error,
                  this.state.translations.ApptCanceledAutomatically,
                  this.state.translations.Close
                );
              } else {
                utils.showErrMsg(
                  this.state.translations.Error,
                  this.state.translations.SomeThingWentWrong,
                  this.state.translations.Close,
                  this.state.redirectUrl
                );
              }
            }
          } catch (error) {
            console.log("ERR IN CANCEL APPT: ", error.message);
            if (error.message.includes("timeout")) {
              utils.showErrMsg(
                this.state.translations.Error,
                this.state.translations.ServerNotResponding,
                this.state.translations.Close,
                this.state.redirectUrl
              );
            } else {
              utils.showErrMsg(
                this.state.translations.Error,
                this.state.translations.SomeThingWentWrong,
                this.state.translations.Close,
                this.state.redirectUrl
              );
            }
          }
          break;

        case "reschedule":
          break;

        default:
      }
    });
  };

  renderAppointmentInfo = () => {
    return (
      <>
        <center>
          <h1 style={{ marginBottom: "20px" }}>
            {this.state.translations.AppointmentInfo}
          </h1>
        </center>
        <div
          className="container confirm-container"
          style={{
            width: "60%",
            margin: "0 auto",
            padding: "24px",
            backgroundColor: "#fff",
          }}
        >
          <DetailsRow
            label={this.state.translations.Operation}
            data={this.state.response.category_title}
            fontColor={FONT_COLOR}
          />

          <DetailsRow
            label={this.state.translations.BranchOffice}
            data={this.state.response.branch_name}
            fontColor={FONT_COLOR}
          />

          <DetailsRow
            label={this.state.translations.SeeYouOn}
            data={`${this.state.response.appointment_date} (${utils.timeFormat(
              this.state.response.start_time
            )})`}
            fontColor={FONT_COLOR}
          />

          <DetailsRow
            label={this.state.translations.BookingRef}
            data={this.state.response.appointment_number}
            fontColor={FONT_COLOR}
          />
        </div>
        <RowFlexContainer>
          <PrimaryButton
            bgColor={MODIFY_BTN_COLOR}
            fontColor={BTN_FONT_COLOR}
            btnText={this.state.translations.ModifyOrCancelApt}
            onClick={this.modifyAppointment}
          />
        </RowFlexContainer>
      </>
    );
  };

  render() {
    return (
      <>
        <div style={{ marginBottom: "100px" }}></div>
        {this.state.isInfoDataLoaded ? (
          this.renderAppointmentInfo()
        ) : (
          <div
            className="container confirm-container"
            style={{
              width: "60%",
              margin: "0 auto",
              padding: "24px",
              height: "auto",
              backgroundColor: "#fff",
            }}
          >
            <div className="modify-appt-input-container">
              <div className="form-group">
                <center>
                  <label
                    className="modify-input-labels"
                    style={{ color: FONT_COLOR }}
                  >
                    {this.state.translations.AppointmentNumber}
                  </label>
                </center>
                <input
                  type="text"
                  className="form-control modify-inputs"
                  id="appointmentNumber"
                  placeholder={
                    this.state.translations.AppointmentNumberPlaceholder
                  }
                  maxLength={10}
                  style={{ border: "1px solid #d8d8d8" }}
                  onChange={this.onChange}
                />
              </div>

              <div className="form-group">
                <center>
                  <label
                    className="modify-input-labels"
                    style={{ color: FONT_COLOR }}
                  >
                    {this.state.translations.EmailAddress}
                  </label>
                </center>
                <input
                  type="text"
                  id="emailAddress"
                  className="form-control modify-inputs"
                  placeholder={this.state.translations.EmailAddressPlaceHolder}
                  style={{ border: "1px solid #d8d8d8" }}
                  onChange={this.onChange}
                />
              </div>

              <div className="form-group">
                <center>
                  <label
                    className="modify-input-labels"
                    style={{ color: FONT_COLOR }}
                  >
                    {this.state.translations.PhoneNumber}
                  </label>
                </center>
                <input
                  type="text"
                  id="phoneNumber"
                  placeholder={this.state.translations.PhoneNumberPlaceholder}
                  className="form-control modify-inputs"
                  style={{ border: "1px solid #d8d8d8" }}
                  maxLength={14}
                  onChange={this.onChange}
                />
              </div>
            </div>
            {this.state.enableBtn && (
              <RowFlexContainer>
                <PrimaryButton
                  bgColor={SUBMIT_BTN_COLOR}
                  fontColor={BTN_FONT_COLOR}
                  btnText={this.state.translations.Submit}
                  onClick={() =>
                    !this.state.dataLoaded
                      ? null
                      : this.getAppointmentInfo(this.state.appointmentNumber)
                  }
                  showLoader={!this.state.dataLoaded}
                />
              </RowFlexContainer>
            )}
          </div>
        )}
      </>
    );
  }
}

export default ModifyAppointment;
