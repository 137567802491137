import swal from "sweetalert";
import moment from "moment";
import * as $ from "jquery";
import { isIOS } from "mobile-device-detect";

const queryString = require("query-string");

const {
  DEFAULT_LANGUAGE,
  TIME_HOUR_FORMAT,
  REDIRECT_TO_CURRENT_PAGE,
  AUDIOS: { TICKET_CALL },
  URLs: { WIZARD_URL },
  MAP_CENTER: { LAT, LNG },
} = window["app-config"].APPLICATION_PROPERTIES;

var WIZARD_URLs;
const {
  SLOTS_SCREEN: {
    DATE_FONT_COLOR,
    SLOTS_FONT_COLOR,
    SELECTED_SLOT_BG_COLOR,
    SELECTED_SLOT_FONT_COLOR,
    SELECTED_DATE_BG_COLOR,
    SELECTED_DATE_BORDER_COLOR,
    SELECTED_DATE_FONT_COLOR,
    DAYS_MONTH_NAME_FONT_COLOR,
  },
} = window["theme"].THEME;

export const validation = {
  isEmailAddress: function (str) {
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return pattern.test(str); // returns a boolean
  },
  isNotEmpty: function (str) {
    var pattern = /\S+/;
    return pattern.test(str); // returns a boolean
  },
  isNumber: function (str) {
    var pattern = /^\d+$/;
    return pattern.test(str); // returns a boolean
  },
  isSame: function (str1, str2) {
    return str1 === str2;
  },
};

export const formatDate = () => {
  var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

export const setTranslations = () => {
  const urlLang = queryString.parse(window.location.search).lang;
  let languages = window["localization"];
  var langs = Object.keys(languages);
  var translations = {};

  for (let i = 0; i < langs.length; i++) {
    if (urlLang === undefined) {
      translations = languages[DEFAULT_LANGUAGE];
      break;
    }

    if (urlLang !== "" && langs[i] === urlLang) {
      let key = langs[i];
      translations = languages[key];
      break;
    }
  }
  return translations;
};

export const setAppLanguageToLocalStorge = () => {
  const language = queryString.parse(window.location.search).lang;
  document.title = setTranslations().AppTitle;
  if (language === undefined || language === "") {
    localStorage.setItem("lang", DEFAULT_LANGUAGE);
  } else if (language.toLowerCase() === "sp") {
    localStorage.setItem("lang", "es");
  } else {
    localStorage.setItem("lang", language);
  }
};

export const applicationMode = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    console.log("DEVELOPMENT MODE");
  } else if (!process.env.NODE_ENV || process.env.NODE_ENV === "production") {
    console.log = () => {};
    document.onkeydown = (e) => {
      if (e.keyCode == 123) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
        return false;
      }

      if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
        return false;
      }
    };

    document.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  }
};
export const SetConfig = async (data) => {
  WIZARD_URLs = data?.data?.APPLICATION_PROPERTIES?.URLs?.WIZARD_URL;
};
export const setDateTimeSlotTheme = (data) => {
  $(document).ready(function () {
    $(".datepicker-switch").css({ color: DAYS_MONTH_NAME_FONT_COLOR });
    $(".dow").css({ color: DAYS_MONTH_NAME_FONT_COLOR });
    $(".timeslot").click(function () {
      $(".timeslot").each((i, d) => {
        $(d).css("background-color", `#fff`);

        $(d).css("color", SLOTS_FONT_COLOR);
      });

      $(this).css("background-color", `${SELECTED_SLOT_BG_COLOR}`);
      $(this).css("color", `${SELECTED_SLOT_FONT_COLOR}`);
    });

    $(".day").css({ color: DATE_FONT_COLOR });
    $(".active").css({
      color: `${SELECTED_DATE_FONT_COLOR}`,
      backgroundColor: SELECTED_DATE_BG_COLOR,
      borderTop: `1px solid ${SELECTED_DATE_BORDER_COLOR}`,
    });
  });
};

export const getOs = () => {
  const os = ["Windows", "Linux", "Mac"]; // add your OS values
  return os.find((v) => navigator.appVersion.indexOf(v) >= 0);
};

export const iosLocationPermission = {
  options: {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  },

  success: function success(pos) {
    console.log("Your current position is:");
    console.log(`Latitude : ${pos.coords.latitude}`);
    console.log(`Longitude: ${pos.coords.longitude}`);
    console.log(`More or less ${pos.coords.accuracy} meters.`);
    return { lat: pos.coords.latitude, lng: pos.coords.longitude };
  },

  error: function error(err) {
    return { lat: LAT, lng: LNG };
  },
};

// Simulate a location service
export const getUserLocation = function (onSuccess, onError) {
  let defaultLocation = { lat: LAT, lng: LNG };

  navigator.geolocation.getCurrentPosition(
    function (position) {
      console.log("POS: ", position);
      let allowedLocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      localStorage.setItem("location", JSON.stringify(allowedLocation));
    },
    function (err) {
      console.log("DENIED: ", err);
      localStorage.setItem("location", JSON.stringify(defaultLocation));
    },
    { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
  );

  (async () => {
    if (navigator.permissions && navigator.permissions.query) {
      const result = await navigator.permissions.query({
        name: "geolocation",
      });

      result.onchange = (state) => {
        console.log("IN CHANGE: ", state);
        setTimeout(function () {
          window.location.reload(true);
        }, 2000);
      };
    }
  })();
};

export const setDatePickerLanguage = () => {
  $.fn.datepicker.dates["es"] = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
    daysMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    today: "Hoy",
    monthsTitle: "Meses",
    clear: "Borrar",
    weekStart: 0,
    format: "dd/mm/yyyy",
  };

  $.fn.datepicker.dates["ar"] = {
    days: [
      "الأحد",
      "الاثنين",
      "الثلاثاء",
      "الأربعاء",
      "الخميس",
      "الجمعة",
      "السبت",
      "الأحد",
    ],
    daysShort: [
      "أحد",
      "اثنين",
      "ثلاثاء",
      "أربعاء",
      "خميس",
      "جمعة",
      "سبت",
      "أحد",
    ],
    daysMin: ["ح", "ن", "ث", "ع", "خ", "ج", "س", "ح"],
    months: [
      "يناير",
      "فبراير",
      "مارس",
      "أبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ],
    monthsShort: [
      "يناير",
      "فبراير",
      "مارس",
      "أبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ],
    today: "هذا اليوم",
    rtl: !0,
  };

  $.fn.datepicker.dates["fr"] = {
    days: [
      "dimanche",
      "lundi",
      "mardi",
      "mercredi",
      "jeudi",
      "vendredi",
      "samedi",
    ],
    daysShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
    daysMin: ["d", "l", "ma", "me", "j", "v", "s"],
    months: [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ],
    monthsShort: [
      "janv.",
      "févr.",
      "mars",
      "avril",
      "mai",
      "juin",
      "juil.",
      "août",
      "sept.",
      "oct.",
      "nov.",
      "déc.",
    ],
    today: "Aujourd'hui",
    monthsTitle: "Mois",
    clear: "Effacer",
    weekStart: 1,
    format: "dd/mm/yyyy",
  };
};

export const timeFormat = (time) => {
  if (TIME_HOUR_FORMAT === "12") {
    return moment(time, "hh:mm").format("hh:mm A");
  } else {
    return time;
  }
};

export const redirect = () => {
  window.location.href = WIZARD_URLs;
};

export const urls = {
  MODIFY_APPOINTMENT: `${window.location.origin}/modifyappointment?appointmentnumber={{appointment_number}}&lang=`,
  CHECK_IN: `${window.location.origin}/checkin`,
};

export const showErrMsg = async (title, message, okBtnText, url) => {
  try {
    let resp = await swal(title, message, "error", {
      buttons: {
        ok: {
          text: okBtnText,
          value: "ok",
        },
      },
    });
    if (resp === "ok") {
      redirect(url);
    }
  } catch (error) {
    console.log("ERROR: ", error.message);
  }
};

export const showErrorMessage = async (
  title,
  message,
  okBtnText,
  redirectUrl
) => {
  let resp = await swal(title, message, "error", {
    buttons: {
      ok: {
        text: okBtnText,
        value: "ok",
      },
    },
  });
  if (resp === "ok") {
    redirect(WIZARD_URLs !== undefined ? WIZARD_URLs : window.location.href);
  }
};

export const showAlertMeg = async (
  title,
  message,
  okBtnText,
  redirectUrl
) => {
  let resp = await swal(title, message, "error", {
    buttons: {
      ok: {
        text: okBtnText,
        value: "ok",
      },
    },
  });
};

export const removeKeyFromUrl = (path, key, otherQueryString) => {
  let urlObject = queryString.parse(window.location.search);
  if (urlObject.appointmentnumber !== undefined) {
    delete urlObject[key];
  }
  let url = `${window.location.origin}/${path}?lang=${
    urlObject.lang === undefined ? DEFAULT_LANGUAGE : urlObject.lang
  }${otherQueryString !== undefined ? otherQueryString : ""}`;
  return REDIRECT_TO_CURRENT_PAGE ? url : WIZARD_URLs;
};

export const validateEmail = (email) => {
  var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  return emailReg.test(email);
};

export const showSuccessMessage = async (message, okBtnText, redirectUrl) => {
  try {
    let resp = await swal("", message, "success", {
      buttons: {
        ok: {
          text: okBtnText,
          value: "ok",
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
    });
    if (resp === "ok") {
      window.location.href = redirectUrl;
    }
  } catch (error) {
    console.log("ERR: ", error);
  }
};

export const notify = () => {
  var audio = new Audio(`${process.env.PUBLIC_URL + `/audio/${TICKET_CALL}`}`);
  audio.play();
};

export const onBrowserReload = (callback) => {
  var type;
  if (window.performance.getEntriesByType("navigation")) {
    type = window.performance.getEntriesByType("navigation")[0].type;

    if (type == "navigate") {
      console.log("TYPE_NAVIGATE");
    }
    if (type == "reload") {
      callback();
    }
    if (type == "back_forward") {
      console.log("BACK_FORWARD");
    }
    if (type == "prerender") {
      console.log("PRE_RENDER");
    }
  }
};
