import React, { useEffect } from "react";

import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./components/Header";
import * as utils from "./utills/utils";
import "./App.css";
import Main from "./containers/Main";
import Checkin from "./containers/Checkin";
import ModifyAppointment from "./containers/ModifyAppointment";
import { isIE } from "react-device-detect";
import AppInfo from "./containers/AppInfo";
import { useState } from "react";
import { HttpService } from "./httpServices/httpService";
import Confirm from "./containers/Confirm";
import UserDetails from "./containers/UserDetails";
import Branch from "./containers/Branch";
import { useClearCache } from 'react-clear-cache';

let { APP_ROUTE, ENABLE_PROD_RESTRICTIONS, VERSION } =
  window["app-config"].APPLICATION_PROPERTIES;

const App = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  useEffect(() => {
    // Hard Reloading ...
    if (!isLatestVersion || localStorage.getItem("version") != VERSION || localStorage.getItem("version") === null || localStorage.getItem("version") === undefined) {
      localStorage.setItem("version", VERSION);
      emptyCacheStorage();
    }

  }, [])
  ENABLE_PROD_RESTRICTIONS && utils.applicationMode();
  utils.setAppLanguageToLocalStorge();
  const [Data, setData] = useState();
  const httpService = new HttpService();



  var {
    URLs: { Path },
  } = window["app-config"].APPLICATION_PROPERTIES;
  useEffect(() => {
    fetch(Path)
      .then((response) => response.json())
      .then((data) => {
        if (data !== null && data !== undefined) {
          httpService.SetConfig(data);
          utils.SetConfig(data);
          setData(data);
        }
      })
      .catch((err) => console.log("err", err));
  }, []);
  console.log("Data", Data);
  // if (isIE) return <></>;
  return (
    <>
      {Data !== undefined ? (
        <Router basename={"AppCitas"}>
          <Header />
          <Route path={`/${APP_ROUTE}`} component={Main} exact />
          <Route path={`/checkin`} component={Checkin} exact />
          <Route
            path={`/modifyappointment`}
            component={ModifyAppointment}
            exact
          />
          <Route path={`/appinfo`} component={AppInfo} exact />
        </Router>
      ) : (
        ""
      )}
    </>
  );
};

export default App;
